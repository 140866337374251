import React from "react";
import {SidebarSubmenuItem, SidebarSubmenuItemActive} from "../Item";

export const SidebarSubmenu = ({subMenuName, subMenuItems, activeMenu}) => {
    // console.log(activeMenu)
    return (
        <div className="mt-5">
            <p className={"font-semibold mb-5 text-gray-500 text-sm"}>
                {subMenuName}
            </p>

            {subMenuItems.map((d, i) => {
                const splitMenu = d.link.split("/");
                const activeMenuSplit = activeMenu.split("/");
                return activeMenuSplit[1] === splitMenu[1] ? (
                    <SidebarSubmenuItemActive
                        item={d.name}
                        icon={d.icon}
                        key={i}
                        link={d.link}
                    />
                ) : (
                    <SidebarSubmenuItem
                        item={d.name}
                        icon={d.icon}
                        key={i}
                        link={d.link}
                    />
                );
            })}
        </div>
    );
};
