import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {getLogAccessDatas,} from "../../../redux/modules/LogAccess/action";
import moment from 'moment';

class LogAccess extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getLogAccessDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        let datas = np.logAccess.datas

        let sortedDatas = datas.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
        });

        this.setState({
            datas: sortedDatas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const logAccessColumn = [
            {
                name: "Date",
                path: "createdAt",
                show: true,
                render: (data) => moment(data).format("LLL")
            },
            {
                name: "Access",
                path: "access",
                show: true,
                render: (data) => data?.name,
            },
            {
                name: "Employee",
                path: "employee",
                show: true,
                render: (data) => data?.name,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        path={this.props.location.pathname}
                        title={"LogAccess Data"}
                        data={this.state.datas}
                        columns={logAccessColumn}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    logAccess: state.logAccess,
});

const mapDispatchToProps = {
    getLogAccessDatas,
};

export default MainLayout(connect(mapStateToProps, mapDispatchToProps)(LogAccess));
