import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {getDeletedVideoDatas, hardDeleteVideoData, restoreVideoData,} from "../../../redux/modules/Video/action";

class VideoTrash extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getDeletedVideoDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.video.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const videoColumn = [
            {
                name: "Name",
                path: "name",
                show: true,
            },
            {
                name: "Video",
                path: "video",
                show: true,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        path={this.props.location.pathname}
                        title={"Video Data"}
                        data={this.props.video.datas}
                        columns={videoColumn}
                        trashAction={true}
                        deleteAction={(id) => this.props.hardDeleteVideoData(id)}
                        restoreAction={(id) => this.props.restoreVideoData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    video: state.video,
});

const mapDispatchToProps = {
    getDeletedVideoDatas,
    hardDeleteVideoData,
    restoreVideoData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(VideoTrash)
);
