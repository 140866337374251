import {learnTypes} from "./type";
import {BaseAction} from "../../actions/base";

const types = learnTypes;

const path = `iot/learn`;

const base = new BaseAction(types);

export const getLearnDatas = () => (dispatch) => {
    return base.getDatas(path, dispatch);
};

export const getDeletedLearnDatas = () => (dispatch) => {
    return base.getDatas(`${path}/deleted`, dispatch);
};

export const getLearnData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}`, dispatch);
};

export const getDeletedLearnData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}/deleted`, dispatch);
};

export const addLearnData = (newData) => (dispatch) => {
    return base.addData(path, dispatch, newData);
};

export const editLearnData = (id, newData) => (dispatch) => {
    return base.editData(`${path}/${id}`, dispatch, newData);
};

export const deleteLearnData = (id) => (dispatch) => {
    return base.deleteData(`${path}/${id}`, dispatch, id);
};

export const restoreLearnData = (id) => (dispatch) => {
    return base.restoreData(`${path}/${id}/restore`, dispatch, id);
};

export const hardDeleteLearnData = (id) => (dispatch) => {
    return base.hardDeleteData(`${path}/${id}/confirmdelete`, dispatch, id);
};
