import {authTypes} from "./type";
import axios from "../../../config/axios";
import {toast} from "react-toastify";

const types = authTypes;

const path = `auth/login`;

export const login = (data, callback) => (dispatch) => {
    return axios().post(`${path}`, data).then((res) => {
        const {data} = res.data
        const {accessToken} = data
        if (data.login) {
            toast.success(`Berhasil login`);

            localStorage.setItem('nekot', accessToken)
            axios().post(`hrd/attendance/graphql`,
                {
                    query: `{
                        aboutme {
                            id
                            name
                            avatar
                            workgroup {
                                id
                                name
                                flexsibleable
                            }
                        }
                    }`
                }).then((res) => {
                dispatch({
                    type: types.success,
                    payload: res.data.data.aboutme,
                });

                // console.log(user, sessionStorage.getItem('nekot'));
            }).catch((err) => {
                toast.error(`Terjadi masalah mendapatkan data user`);
            })
        }
        if (callback) {
            return callback(data);
        }
    });
};

export const checkUser = (callback) => (dispatch) => {
    return axios().post(`hrd/attendance/graphql`,
        {
            query: `{
                        aboutme {
                            id
                            name
                            avatar
                            workgroup {
                                id
                                name
                                flexsibleable
                            }
                        }
                    }`
        }).then((res) => {
        dispatch({
            type: types.success,
            payload: res.data.data.aboutme,
        });

        if (callback) {
            return callback(res.data.data.aboutme);
        }

        // console.log(user, sessionStorage.getItem('nekot'));
    }).catch((err) => {
        toast.error(`Terjadi masalah mendapatkan data user`);
    })
}

export const refreshToken = (callback) => (dispatch) => {
    return axios().get(`auth/token/`).then((res) => {
        const {data} = res.data
        const {accessToken} = data

        localStorage.setItem('nekot', accessToken)
        if (callback) {
            return callback(res.data);
        }

        // console.log(user, sessionStorage.getItem('nekot'));
    }).catch((err) => {
        toast.error(`Terjadi masalah refresh token`);
    })
}

export const logout = (callback) => (dispatch) => {
    return axios().get(`auth/logout`).then((res) => {
        const {data} = res.data
        if (data.logout) {
            toast.success(`Berhasil logout`);
            localStorage.removeItem('nekot');
        }

        if (callback) {
            return callback();
        }
        // console.log(user, sessionStorage.getItem('nekot'));
    }).catch((err) => {
        toast.error(`Terjadi masalah refresh token`);
    })
}

