import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {deleteGuestCardData, getGuestCardDatas,} from "../../../redux/modules/GuestCard/action";

class GuestCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getGuestCardDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.guestCard.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const guestCardColumn = [
            {
                name: "Name",
                path: "name",
                show: true,
            },
            {
                name: "Number",
                path: "accessNumber",
                show: true,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        addData={true}
                        trashBin={true}
                        path={this.props.location.pathname}
                        title={"GuestCard Data"}
                        data={this.props.guestCard.datas}
                        columns={guestCardColumn}
                        action={["update", "delete"]}
                        trash={true}
                        deleteAction={(id) => this.props.deleteGuestCardData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    guestCard: state.guestCard,
});

const mapDispatchToProps = {
    getGuestCardDatas,
    deleteGuestCardData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(GuestCard)
);
