import React from "react";
import MainLayout from "../../../hoc/main";
import {FormContainer, Grid, Input} from "../../../components";
import {connect} from "react-redux";
import {addAcFreonTypeData} from "../../../redux/modules/AcFreonType/action";
import {Button} from "../../../components/Button";
import {Redirect} from "react-router-dom";

class AcFreonTypeAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
        };
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });
    };

    finishForm(e) {
        e.preventDefault();
        this.props.addAcFreonTypeData(this.state);
    }

    render() {
        if (this.props.acFreonType?.success)
            return <Redirect to="/ac-freon-type"/>;
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <FormContainer className={`pt-0`}>
                        <Input
                            name={"name"}
                            label={"Access freon-type name"}
                            value={this.state.name}
                            placeholder={"Lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <hr className={`my-5`}/>
                        <Button
                            className={`bg-green-200 text-green-700`}
                            onClick={this.finishForm.bind(this)}
                        >
                            Submit
                        </Button>
                    </FormContainer>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    acFreonType: state.acFreonType,
});

const mapDispatchToProps = {
    addAcFreonTypeData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(AcFreonTypeAdd)
);
