import React from "react";
import MainLayout from "../../../hoc/main";
import {FormContainer, Grid, Input} from "../../../components";
import {connect} from "react-redux";
import {editEntityData, getEntityData,} from "../../../redux/modules/Entity/action";
import {Button} from "../../../components/Button";
import {Redirect} from "react-router-dom";

class EntityEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayName: "",
            entities: []
        };
    }

    componentDidMount() {
        this.props.getEntityData(this.props.match.params.id);
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            displayName: np.entity.data.displayName,
            entities: np.entity.data.entities
        });
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });
    };

    finishForm(e) {
        e.preventDefault();
        this.props.editEntityData(this.props.match.params.id, this.state);
    }

    render() {
        if (this.props.entity?.success) return <Redirect to="/entity"/>;
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <FormContainer className={`pt-0`}>
                        <Input
                            name={"displayName"}
                            label={"Entity name"}
                            value={this.state.displayName}
                            placeholder={"Lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <h2 className={"mt-5 font-medium"}>Values</h2>

                        {
                            this.state?.entities?.map((ent, i) => <div key={i}
                                                                       className={"mt-5 bg-gray-100 p-5 rounded-md"}>
                                <div className="flex flex-row items-center">
                                    <h3>Value</h3>
                                    <input
                                        className={
                                            "ml-5 appearance-none border rounded py-2 px-3 text-gray-700 focus:bg-gray-300 focus:outline-none text-sm w-full md:w-1/2 xl:w-1/3 disabled:bg-gray-700 bg-gray-300"
                                        }
                                        value={this.state.entities[i]?.value}
                                        onChange={(e) =>
                                            this.setState((prevState) => {
                                                let entities = [...prevState.entities];
                                                entities[i].value = e.target.value
                                                return {entities}
                                            })
                                        }
                                    />
                                    <Button className={"bg-red-300 text-red-500 rounded"} onClick={(e) => {
                                        this.setState(prevState => {
                                            let entities = [...prevState.entities];
                                            entities.splice(i, 1);

                                            return {entities};
                                        })
                                    }}>X</Button>
                                </div>
                                <hr className={"my-3 border-gray-300"}/>

                                <h3 className={"mt-3"}>Synonyms</h3>
                                {
                                    ent?.synonyms?.map((syn, iSyn) => <div className="flex flex-row items-center">
                                        <input
                                            className={
                                                "mt-3 appearance-none border rounded py-2 px-3 text-gray-700 focus:bg-gray-300 focus:outline-none text-sm w-full md:w-1/2 xl:w-1/3 disabled:bg-gray-700 bg-gray-300"
                                            }
                                            value={syn}
                                            onChange={(e) =>
                                                this.setState((prevState) => {
                                                    let entities = [...prevState.entities];
                                                    entities[i].synonyms[iSyn] = e.target.value
                                                    return {entities}
                                                })
                                            }
                                        />
                                        <Button className={"bg-yellow-300 text-yellow-500 rounded"} onClick={(e) => {
                                            this.setState(prevState => {
                                                let entities = [...prevState.entities];
                                                entities[i].synonyms.splice(iSyn, 1);

                                                return {entities};
                                            })
                                        }}>X</Button>
                                    </div>)
                                }
                                <Button
                                    className={`bg-pink-200 text-pink-700 mt-5`}
                                    onClick={(e) => {
                                        e.preventDefault();

                                        this.setState((prevState) => {
                                            let entities = [...prevState.entities];

                                            entities[i].synonyms.push("");

                                            return {entities};
                                        });
                                    }}
                                >
                                    Add new synonym
                                </Button>
                            </div>)
                        }

                        <Button
                            className={`bg-pink-200 text-pink-700 mt-5`}
                            onClick={(e) => {
                                e.preventDefault();

                                this.setState((prevState) => {
                                    let entities = [...prevState.entities];

                                    entities.push({value: "", synonyms: []});

                                    return {entities};
                                });
                            }}
                        >
                            Add new values
                        </Button>

                        <hr className={`my-5`}/>
                        <Button
                            className={`bg-green-200 text-green-700`}
                            onClick={this.finishForm.bind(this)}
                        >
                            Submit
                        </Button>
                    </FormContainer>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    entity: state.entity,
});

const mapDispatchToProps = {
    editEntityData,
    getEntityData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(EntityEdit)
);
