import {cardReaderTypes} from "./type";
import {BaseAction} from "../../actions/base";

const types = cardReaderTypes;

const path = `iot/card-reader`;

const base = new BaseAction(types);

export const getCardReaderDatas = () => (dispatch) => {
    return base.getDatas(path, dispatch);
};

export const getDeletedCardReaderDatas = () => (dispatch) => {
    return base.getDatas(`${path}/deleted`, dispatch);
};

export const getCardReaderData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}`, dispatch);
};

export const getDeletedCardReaderData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}/deleted`, dispatch);
};

export const addCardReaderData = (newData) => (dispatch) => {
    return base.addData(path, dispatch, newData);
};

export const editCardReaderData = (id, newData) => (dispatch) => {
    return base.editData(`${path}/${id}`, dispatch, newData);
};

export const deleteCardReaderData = (id) => (dispatch) => {
    return base.deleteData(`${path}/${id}`, dispatch, id);
};

export const restoreCardReaderData = (id) => (dispatch) => {
    return base.restoreData(`${path}/${id}/restore`, dispatch, id);
};

export const hardDeleteCardReaderData = (id) => (dispatch) => {
    return base.hardDeleteData(`${path}/${id}/confirmdelete`, dispatch, id);
};
