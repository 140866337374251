import React from "react";
import {FaStream} from "react-icons/fa";

export const LayoutTitle = ({title, openMenu}) => {
    return (
        <div
            className="fixed w-full bg-green-800 text-gray-100  flex items-center z-10 p-6 xl:pb-0 xl:block text-lg xl:text-2xl font-bold xl:text-gray-700 xl:static xl:bg-transparent">
            <button
                className="appearance-none focus:outline-none xl:hidden cursor-pointer mr-5 active:border-none focus:border-none hover:border-none"
                onClick={openMenu}
            >
                <FaStream/>
            </button>
            {title}
        </div>
    );
};
