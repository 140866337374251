import React from "react";
import MainLayout from "../../../hoc/main";
import {FormContainer, Grid, Input} from "../../../components";
import {connect} from "react-redux";
import {editGuestCardData, getGuestCardData,} from "../../../redux/modules/GuestCard/action";
import {Button} from "../../../components/Button";
import {Redirect} from "react-router-dom";

class GuestCardEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            accessNumber: "",
        };
    }

    componentDidMount() {
        this.props.getGuestCardData(this.props.match.params.id);
    }

    UNSAFE_componentWillReceiveProps(np) {
        console.log(np);
        this.setState({
            name: np.guestCard.data.name,
            accessNumber: np.guestCard.data.accessNumber,
        });
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });
    };

    finishForm(e) {
        e.preventDefault();
        this.props.editGuestCardData(this.props.match.params.id, this.state);
    }

    render() {
        if (this.props.guestCard?.success) return <Redirect to="/guest-card"/>;
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <FormContainer className={`pt-0`}>
                        <Input
                            name={"name"}
                            label={"Guest Card name"}
                            value={this.state.name}
                            placeholder={"Lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <Input
                            name={"accessNumber"}
                            label={"Guest Card Access Number"}
                            value={this.state.accessNumber}
                            placeholder={"xxxxxxxxxxxxxxxxxx"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <hr className={`my-5`}/>
                        <Button
                            className={`bg-green-200 text-green-700`}
                            onClick={this.finishForm.bind(this)}
                        >
                            Submit
                        </Button>
                    </FormContainer>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    guestCard: state.guestCard,
});

const mapDispatchToProps = {
    editGuestCardData,
    getGuestCardData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(GuestCardEdit)
);
