import {entityTypes} from "./type";
import {BaseAction} from "../../actions/base";

const types = entityTypes;

const path = `iot/entity`;

const base = new BaseAction(types);

export const getEntityDatas = () => (dispatch) => {
    return base.getDatas(path, dispatch);
};

export const getDeletedEntityDatas = () => (dispatch) => {
    return base.getDatas(`${path}/deleted`, dispatch);
};

export const getEntityData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}`, dispatch);
};

export const getDeletedEntityData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}/deleted`, dispatch);
};

export const addEntityData = (newData) => (dispatch) => {
    return base.addData(path, dispatch, newData);
};

export const editEntityData = (id, newData) => (dispatch) => {
    return base.editData(`${path}/${id}`, dispatch, newData);
};

export const deleteEntityData = (id) => (dispatch) => {
    return base.deleteData(`${path}/${id}`, dispatch, id);
};

export const restoreEntityData = (id) => (dispatch) => {
    return base.restoreData(`${path}/${id}/restore`, dispatch, id);
};

export const hardDeleteEntityData = (id) => (dispatch) => {
    return base.hardDeleteData(`${path}/${id}/confirmdelete`, dispatch, id);
};
