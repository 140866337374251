import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {getDeletedTimerDatas, hardDeleteTimerData, restoreTimerData,} from "../../../redux/modules/Timer/action";
import moment from "moment";

class TimerTrash extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getDeletedTimerDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.timer.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const timerColumn = [
            {
                name: "Name",
                path: "name",
                show: true,
            },
            {
                name: "Days",
                path: "days",
                show: true,
                render: (data) => {
                    const days = {
                        1: 'Senin',
                        2: 'Selasa',
                        3: 'Rabu',
                        4: 'Kamis',
                        5: 'Jumat',
                        6: 'Sabtu',
                        7: 'Minggu',
                    }
                    let dayNames = data.map(d => days[d])
                    return dayNames.join(', ')
                }
            },
            {
                name: "Time",
                path: "time",
                show: true,
                render: (data) => moment(data).format("HH:mm"),
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        path={this.props.location.pathname}
                        title={"Timer Category Data"}
                        data={this.props.timer.datas}
                        columns={timerColumn}
                        trashAction={true}
                        deleteAction={(id) => this.props.hardDeleteTimerData(id)}
                        restoreAction={(id) => this.props.restoreTimerData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    timer: state.timer,
});

const mapDispatchToProps = {
    getDeletedTimerDatas,
    hardDeleteTimerData,
    restoreTimerData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(TimerTrash)
);
