import React from "react";
import MainLayout from "../../../hoc/main";
import {Datepicker, FormContainer, Grid, Input, TextArea} from "../../../components";
import {connect} from "react-redux";
import {addGuestData} from "../../../redux/modules/Guest/action";
import {Button} from "../../../components/Button";
import {Redirect} from "react-router-dom";

class GuestAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            identityNumber: "",
            location: "",
            company: "",
            gender: "",
            address: "",
            birthOfDate: "",
        };
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });
    };

    onChangeCheckBox = (e) => {
        const {checked, name} = e.target;
        this.setState({
            [name]: checked,
        });
    };

    finishForm(e) {
        e.preventDefault();
        this.props.addGuestData(this.state);
    }

    render() {
        if (this.props.guest?.success) return <Redirect to="/guest"/>;
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <FormContainer className={`pt-0`}>
                        <Input
                            name={"name"}
                            label={"Guest name"}
                            value={this.state.name}
                            placeholder={"Lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <Input
                            name={"identityNumber"}
                            label={"Guest identity number"}
                            value={this.state.identityNumber}
                            placeholder={"xxxxxxxxxxxxxxxxxx"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <Input
                            name={"location"}
                            label={"Location"}
                            value={this.state.location}
                            placeholder={"Guest city"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <Input
                            name={"company"}
                            label={"Company"}
                            value={this.state.company}
                            placeholder={"Company name"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <TextArea
                            name={"address"}
                            label={"Address"}
                            value={this.state.address}
                            placeholder={"Lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                            rows={5}
                        />
                        <Datepicker
                            name={"birthOfDate"}
                            label={"BoD"}
                            value={this.state.birthOfDate}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />

                        <hr className={`my-5`}/>
                        <Button
                            className={`bg-green-200 text-green-700`}
                            onClick={this.finishForm.bind(this)}
                        >
                            Submit
                        </Button>
                    </FormContainer>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    guest: state.guest,
});

const mapDispatchToProps = {
    addGuestData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(GuestAdd)
);
