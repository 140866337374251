import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {deleteActionData, getActionDatas,} from "../../../redux/modules/Action/action";

class Action extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getActionDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.action.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const actionColumn = [
            {
                name: "Name",
                path: "name",
                show: true,
            },
            {
                name: "Device",
                path: "device",
                show: true,
                render: (data) => data?.name,
            },
            {
                name: "Action",
                path: "action",
                show: true,
            },
            {
                name: "Status",
                path: "status",
                show: true,
                render: (data) => (data ? "Active" : "Not Active"),
            },
            {
                name: "Pin",
                path: "pin",
                show: true,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        addData={true}
                        trashBin={true}
                        path={this.props.location.pathname}
                        title={"Action Data"}
                        data={this.props.action.datas}
                        columns={actionColumn}
                        action={["update", "delete"]}
                        trash={true}
                        deleteAction={(id) => this.props.deleteActionData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    action: state.action,
});

const mapDispatchToProps = {
    getActionDatas,
    deleteActionData,
};

export default MainLayout(connect(mapStateToProps, mapDispatchToProps)(Action));
