import React from "react";
import MainLayout from "../../../hoc/main";
import {Checkbox, Dropdown, FormContainer, Grid, Input, Timepicker} from "../../../components";
import {connect} from "react-redux";
import {addTimerData} from "../../../redux/modules/Timer/action";
import {getActionDatas} from "../../../redux/modules/Action/action";
import {Button} from "../../../components/Button";
import {Redirect} from "react-router-dom";
import {FaTrashAlt} from "react-icons/fa";

class TimerAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            days: [],
            time: "",
            actions: [],
        };
    }

    componentDidMount() {
        this.props.getActionDatas();
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });
    };

    onChangeCheckBox = (e) => {
        const {checked, name} = e.target;
        this.setState({
            [name]: checked,
        });
    };

    finishForm(e) {
        e.preventDefault();
        this.props.addTimerData(this.state);
    }

    letsFindAction(findWhat) {
        let data = this.props.action?.datas?.find((x) => x.id === findWhat);

        return data;
    }

    onCheckChange(id, e) {
        let checked = e.target.checked;
        this.setState((prevState) => {
            if (checked) {
                let days = [...prevState.days, id];
                days = days.sort();
                return {days};
            } else {
                let days = prevState.days;
                const index = days.indexOf(id);

                if (index > -1) {
                    days.splice(index, 1);
                }

                return {days};
            }
        });
    }

    render() {
        if (this.props.timer?.success) return <Redirect to="/timer"/>;
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <FormContainer className={`pt-0`}>
                        <Input
                            name={"name"}
                            label={"Timer Name"}
                            value={this.state.name}
                            placeholder={"All On"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <div className="mt-5">
                            <label htmlFor={'days'} className={"block font-medium w-1/5 mb-2 mt-5"}>
                                Days
                            </label>
                            <div className="flex">
                                {
                                    [
                                        {key: 1, value: "Senin"},
                                        {key: 2, value: "Selasa"},
                                        {key: 3, value: "Rabu"},
                                        {key: 4, value: "Kamis"},
                                        {key: 5, value: "Jumat"},
                                        {key: 6, value: "Sabtu"},
                                        {key: 7, value: "Minggu"},
                                    ].map((day, index) => <Checkbox
                                        label={day.value}
                                        key={day.key}
                                        checked={this.state.days?.includes(day.key)}
                                        onChange={this.onCheckChange.bind(this, day.key)}
                                        className={"mr-2"}
                                    />)
                                }
                            </div>
                        </div>
                        <Timepicker
                            name={"time"}
                            label={"Time"}
                            value={this.state.time}
                            placeholder={"All On"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={(e) => {
                                let today = new Date();
                                let setTime = new Date(
                                    today.getFullYear(),
                                    today.getMonth(),
                                    today.getDate(),
                                    parseInt(e.target.value.substring(0, 2)),
                                    parseInt(e.target.value.slice(-2)),
                                    0
                                );
                                this.setState({time: setTime.toISOString()});
                            }}
                        />
                        <div className="mt-5">
                            <label htmlFor={'days'} className={"block font-medium w-1/5 mb-2 mt-5"}>
                                Actions
                            </label>
                            <div className="m-5 p-5 pt-0">
                                {this.state.actions.length > 0 ? (
                                    this.state.actions.map((act, i) => {
                                        return (
                                            <div key={i} className={"mt-5 bg-gray-100 p-5 rounded-md"}>
                                                <div className={"font-medium flex items-center mr-5"}>
                                                    Action - {i + 1}
                                                    <Button
                                                        className={`bg-red-200 text-red-700 ml-5`}
                                                        onClick={(e) => {
                                                            e.preventDefault();

                                                            this.setState((prevState) => {
                                                                let actions = [...prevState.actions];
                                                                actions.splice(i, 1);

                                                                return {actions};
                                                            });
                                                        }}
                                                    >
                                                        <FaTrashAlt/>
                                                    </Button>
                                                </div>
                                                <hr className={"my-3 border-gray-500"}/>
                                                <div className="flex flex-col">
                                                    <Dropdown
                                                        className={"bg-gray-300 mt-3"}
                                                        placeholder={"Choose Action"}
                                                        data={this.props.action?.datas}
                                                        display={"name"}
                                                        value={this.state.actions[i]?.action}
                                                        expectedValue={"id"}
                                                        name={"action"}
                                                        onChange={(e) => {
                                                            this.setState(prevState => {
                                                                let actions = [...prevState.actions];

                                                                actions[i].action = e.target.value;

                                                                return {actions};
                                                            })
                                                        }}
                                                    />

                                                    {act?.action !== "" && (<div className={"flex flex-col"}>
                                                        {this.letsFindAction(act?.action)?.action ===
                                                            "Switch" &&
                                                            (
                                                                <div className="">
                                                                    <Dropdown
                                                                        className={
                                                                            "bg-gray-300 mt-3 focus:bg-gray-200"
                                                                        }
                                                                        placeholder={"Choose on action"}
                                                                        data={[
                                                                            {display: "ON", value: "1"},
                                                                            {display: "OFF", value: "0"},
                                                                        ]}
                                                                        display={"display"}
                                                                        expectedValue={"value"}
                                                                        value={
                                                                            this.state.actions[i].state[0]
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.setState((prevState) => {
                                                                                let actions = [...prevState.actions];

                                                                                actions[i].state[0] =
                                                                                    e.target.value;

                                                                                return {actions};
                                                                            })
                                                                        }
                                                                    />

                                                                </div>)
                                                        }
                                                    </div>)
                                                    }
                                                </div>

                                            </div>
                                        );
                                    })
                                ) : (
                                    <h3 className={"font-medium"}>No Action</h3>
                                )}
                            </div>
                        </div>
                        <Button
                            className={`bg-pink-200 text-pink-700 mt-5`}
                            onClick={(e) => {
                                e.preventDefault();

                                this.setState((prevState) => {
                                    let actions = [...prevState.actions];

                                    actions.push({action: "", state: []});

                                    return {actions};
                                });
                            }}
                        >
                            Add new action
                        </Button>

                        <hr className={`my-5`}/>
                        <Button
                            className={`bg-green-200 text-green-700`}
                            onClick={this.finishForm.bind(this)}
                        >
                            Submit
                        </Button>
                    </FormContainer>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    timer: state.timer,
    action: state.action
});

const mapDispatchToProps = {
    addTimerData,
    getActionDatas,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(TimerAdd)
);
