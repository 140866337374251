import {showroomDeviceTypes} from "./type";
import {BaseAction} from "../../actions/base";

const types = showroomDeviceTypes;

const path = `iot/showroom-device`;

const base = new BaseAction(types);

export const getShowroomDeviceDatas = () => (dispatch) => {
    return base.getDatas(path, dispatch);
};

export const getDeletedShowroomDeviceDatas = () => (dispatch) => {
    return base.getDatas(`${path}/deleted`, dispatch);
};

export const getShowroomDeviceData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}`, dispatch);
};

export const getDeletedShowroomDeviceData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}/deleted`, dispatch);
};

export const addShowroomDeviceData = (newData) => (dispatch) => {
    return base.addData(path, dispatch, newData);
};

export const editShowroomDeviceData = (id, newData) => (dispatch) => {
    return base.editData(`${path}/${id}`, dispatch, newData);
};

export const deleteShowroomDeviceData = (id) => (dispatch) => {
    return base.deleteData(`${path}/${id}`, dispatch, id);
};

export const restoreShowroomDeviceData = (id) => (dispatch) => {
    return base.restoreData(`${path}/${id}/restore`, dispatch, id);
};

export const hardDeleteShowroomDeviceData = (id) => (dispatch) => {
    return base.hardDeleteData(`${path}/${id}/confirmdelete`, dispatch, id);
};
