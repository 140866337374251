import {FaChevronDown, FaRegCheckSquare, FaRegSquare} from "react-icons/fa";
import React, {useState} from "react";

export const Dropdown = ({
                             name,
                             label,
                             value,
                             onChange,
                             className,
                             placeholder,
                             data,
                             display,
                             expectedValue,
                         }) => {
    // console.log("DROPDOWN", data);
    return (
        <div>
            {label ? (
                <label htmlFor={name} className={"block font-medium mb-3 mt-5"}>
                    {label}
                </label>
            ) : null}

            <select
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                className={`select1 block  py-2 px-3 bg-gray-100 rounded focus:bg-gray-100 focus:shadow-outline focus:outline-none ${className}`}
            >
                <option value="" defaultValue={value === "" ? true : false} hidden>
                    {placeholder}
                </option>
                {data?.map((d, i) => {
                    return (
                        <option key={i} value={d[expectedValue] || d}>
                            {d[display] || d}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};

export const DropdownChecklist = ({data, onClick, className}) => {
    const [openMenu, setOpenMenu] = useState(false);
    return (
        <div className="">
            <div
                className={`relative z-0 text-left py-2 px-3 bg-gray-100 rounded focus:bg-gray-100 focus:shadow-outline focus:outline-none ${className}`}
            >
                <div className="">
                    <button
                        className={`inline-flex justify-center w-full items-center property-none  focus:outline-none `}
                        id="menu-button"
                        aria-expanded="true"
                        aria-haspopup="true"
                        onClick={() => setOpenMenu(!openMenu)}
                    >
                        Show Columns
                        <span className={`ml-3`}>
              <FaChevronDown/>
            </span>
                    </button>
                </div>

                <div
                    className={`transition ease-out duration-100 ${
                        !openMenu
                            ? "transform opacity-0 scale-95"
                            : "transform opacity-100 scale-100"
                    } origin-top-right  absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabIndex="-1"
                >
                    <div className="py-1" role="none">
                        {data.map((d, i) => (
                            <button
                                key={i}
                                className="flex flex-row items-center px-4 py-2 w-full text-left hover:bg-gray-100  focus:outline-none"
                                tabIndex="-1"
                                onClick={() => onClick(i, !d.show)}
                            >
                                {d.show ? <FaRegCheckSquare/> : <FaRegSquare/>}

                                <span className={`ml-3`}>{d.name}</span>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
