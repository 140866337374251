import React from "react";
import MainLayout from "../../../hoc/main";
import {Dropdown, FormContainer, Grid, Input} from "../../../components";
import {connect} from "react-redux";
import {addConnectorData} from "../../../redux/modules/Connector/action";
import {getCardReaderDatas} from "../../../redux/modules/CardReader/action";
import {getActionDatas} from "../../../redux/modules/Action/action";
import {Button} from "../../../components/Button";
import {Redirect} from "react-router-dom";
import {FaTrashAlt} from "react-icons/fa";

class ConnectorAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            trigger: "",
            actions: [{action: "", state: [], delay: 0, stateAfterDelay: []}],
        };
    }

    componentDidMount() {
        this.props.getCardReaderDatas();
        this.props.getActionDatas();
    }

    letsFindAction(findWhat) {
        let data = this.props.action?.datas?.find((x) => x.id === findWhat);

        return data;
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });
    };

    onChangeCheckBox = (e) => {
        const {checked, name} = e.target;
        this.setState({
            [name]: checked,
        });
    };

    finishForm(e) {
        e.preventDefault();
        this.props.addConnectorData(this.state);
    }

    render() {

        if (this.props.connector?.success) return <Redirect to="/connector"/>;
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <FormContainer className={`pt-0`}>
                        <Input
                            name={"name"}
                            label={"Connector name"}
                            value={this.state.name}
                            placeholder={"Lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <h2 className={"mt-5 font-medium"}>Card Reader</h2>
                        <Dropdown
                            className={"bg-gray-200 mt-3"}
                            placeholder={"Choose Card Reader"}
                            data={this.props.cardReader?.datas}
                            display={"name"}
                            value={this.state.trigger}
                            expectedValue={"id"}
                            name={"trigger"}
                            onChange={this.onChangeValue.bind(this)}
                        />

                        <h2 className={"mt-5 font-medium"}>Actions</h2>

                        <div className="m-5 p-5 pt-0">
                            {this.state.actions.length > 0 ? (
                                this.state.actions.map((act, i) => {
                                    return (
                                        <div key={i} className={"mt-5 bg-gray-100 p-5 rounded-md"}>
                                            <div className={"font-medium flex items-center mr-5"}>
                                                Action - {i + 1}
                                                <Button
                                                    className={`bg-red-200 text-red-700 ml-5`}
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        this.setState((prevState) => {
                                                            let actions = [...prevState.actions];
                                                            actions.splice(i, 1);

                                                            return {actions};
                                                        });
                                                    }}
                                                >
                                                    <FaTrashAlt/>
                                                </Button>
                                            </div>
                                            <hr className={"my-3 border-gray-500"}/>
                                            <div className="flex flex-col">
                                                <Dropdown
                                                    className={"bg-gray-300 mt-3"}
                                                    placeholder={"Choose Action"}
                                                    data={this.props.action?.datas}
                                                    display={"name"}
                                                    value={this.state.actions[i]?.action}
                                                    expectedValue={"id"}
                                                    name={"action"}
                                                    onChange={(e) => {
                                                        this.setState(prevState => {
                                                            let actions = [...prevState.actions];

                                                            actions[i].action = e.target.value;

                                                            return {actions};
                                                        })
                                                    }}
                                                />

                                                {act?.action !== "" && (<div className={"flex flex-col"}>
                                                    {this.letsFindAction(act?.action)?.action ===
                                                        "Switch" &&
                                                        (
                                                            <div className="">
                                                                <Dropdown
                                                                    className={
                                                                        "bg-gray-300 mt-3 focus:bg-gray-200"
                                                                    }
                                                                    placeholder={"Choose on action"}
                                                                    data={[
                                                                        {display: "ON", value: "1"},
                                                                        {display: "OFF", value: "0"},
                                                                    ]}
                                                                    display={"display"}
                                                                    expectedValue={"value"}
                                                                    value={
                                                                        this.state.actions[i].state[0]
                                                                    }
                                                                    onChange={(e) =>
                                                                        this.setState((prevState) => {
                                                                            let actions = [...prevState.actions];

                                                                            actions[i].state[0] =
                                                                                e.target.value;

                                                                            return {actions};
                                                                        })
                                                                    }
                                                                />

                                                            </div>)
                                                    }
                                                    <input
                                                        className={
                                                            "mt-5 appearance-none border rounded py-2 px-3 text-gray-700 focus:bg-gray-300 focus:outline-none text-sm w-full md:w-1/2 xl:w-1/3 disabled:bg-gray-700 bg-gray-300"
                                                        }
                                                        type="number"
                                                        value={act?.delay}
                                                        onChange={(e) =>
                                                            this.setState((prevState) => {
                                                                let actions = [...prevState.actions];

                                                                actions[i].delay = e.target.value

                                                                return {actions}
                                                            })
                                                        }
                                                    />
                                                    {
                                                        act?.delay > 0 && (<Dropdown
                                                            className={
                                                                "bg-gray-300 mt-3 focus:bg-gray-200"
                                                            }
                                                            placeholder={"After delay"}
                                                            data={[
                                                                {display: "ON", value: "1"},
                                                                {display: "OFF", value: "0"},
                                                            ]}
                                                            display={"display"}
                                                            expectedValue={"value"}
                                                            value={
                                                                this.state.actions[i].stateAfterDelay[0]
                                                            }
                                                            onChange={(e) =>
                                                                this.setState((prevState) => {
                                                                    let actions = [...prevState.actions];

                                                                    actions[i].stateAfterDelay[0] =
                                                                        e.target.value;

                                                                    return {actions};
                                                                })
                                                            }
                                                        />)
                                                    }
                                                </div>)
                                                }
                                            </div>

                                        </div>
                                    );
                                })
                            ) : (
                                <h3 className={"font-medium"}>No Action</h3>
                            )}
                        </div>

                        <Button
                            className={`bg-pink-200 text-pink-700 mt-5`}
                            onClick={(e) => {
                                e.preventDefault();

                                this.setState((prevState) => {
                                    let actions = [...prevState.actions];

                                    actions.push({action: "", state: [], delay: 0, stateAfterDelay: []});

                                    return {actions};
                                });
                            }}
                        >
                            Add new action
                        </Button>

                        <hr className={`my-5`}/>
                        <Button
                            className={`bg-green-200 text-green-700`}
                            onClick={this.finishForm.bind(this)}
                        >
                            Submit
                        </Button>
                    </FormContainer>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    connector: state.connector,
    cardReader: state.cardReader,
    action: state.action,
});

const mapDispatchToProps = {
    addConnectorData,
    getCardReaderDatas,
    getActionDatas
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(ConnectorAdd)
);
