import {bardiTypes} from "./type";
import {BaseAction} from "../../actions/base";
import axios from "../../../config/axios";
import {toast} from "react-toastify";

const types = bardiTypes;

const path = `iot/bardi/devices`;

const base = new BaseAction(types);

export const getBardiDatas = () => (dispatch) => {
    return base.getDatas(path, dispatch);
};

export const getBardiData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}`, dispatch);
};

export const getBardiFunction = (id) => (dispatch) => {
    return base.getData(`${path}/${id}/functions`, dispatch);
};

export const changeBardiStatus = (id, command, callback) => (dispatch) => {
    return axios().post(`${path}/${id}`, command).then((res) => {
        const {data} = res.data;
        if (data.success) {
            toast.success(`Berhasil mengubah status`);
        } else {
            toast.error(`Gagal mengubah status`);
        }
        if (callback) {
            return callback(data);
        }
    });
};
