import {requestTypes} from "./type";
import {BaseReducer} from "../../reducers/base";

const types = requestTypes;

const initialState = {
    datas: [],
    data: {},
    isLoading: false,
    success: false,
    msg: {},
};

const requestReducer = (state = initialState, action) => {
    const baseClass = new BaseReducer(types, state, action);

    return baseClass.baseReducer();
};

export default requestReducer;
