import React from "react";
import MainLayout from "../../../hoc/main";
import {Dropdown, FormContainer, Grid, Input} from "../../../components";
import {connect} from "react-redux";
import {addActionGroupData} from "../../../redux/modules/ActionGroup/action";
import {getActionDatas} from "../../../redux/modules/Action/action";
import {Button} from "../../../components/Button";
import {Redirect} from "react-router-dom";
import {FaTrashAlt} from "react-icons/fa";

class ActionGroupAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            controllers: [],
            action: "",
        };
    }

    componentDidMount() {
        this.props.getActionDatas();
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });
    };

    onChangeCheckBox = (e) => {
        const {checked, name} = e.target;
        this.setState({
            [name]: checked,
        });
    };

    finishForm(e) {
        e.preventDefault();
        this.props.addActionGroupData(this.state);
    }

    render() {
        if (this.props.actionGroup?.success) return <Redirect to="/action-group"/>;
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <FormContainer className={`pt-0`}>
                        <Input
                            name={"name"}
                            label={"ActionGroup name"}
                            value={this.state.name}
                            placeholder={"Lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <Dropdown
                            label={"Action"}
                            data={[
                                {id: 1, name: "Switch", pin: 1},
                                {id: 1, name: "RGB", pin: 1},
                                {id: 1, name: "Brightness", pin: 1},
                            ]}
                            expectedValue={"name"}
                            display={"name"}
                            name={"action"}
                            value={this.state.action}
                            onChange={this.onChangeValue}
                            placeholder="Choose one action to control"
                        />
                        <h2 className={"mt-5 font-medium"}>Controllers</h2>

                        {
                            this.state.controllers.map((con, i) => <div className={"mt-5 flex flex-row items-center "}>
                                <Dropdown
                                    className={"bg-gray-300 mt-3"}
                                    placeholder={"Choose Controller"}
                                    data={this.props.action?.datas}
                                    display={"name"}
                                    value={this.state.controllers[i]}
                                    expectedValue={"id"}
                                    name={"controllers"}
                                    onChange={(e) => {
                                        this.setState(prevState => {
                                            let controllers = [...prevState.controllers];

                                            controllers[i] = e.target.value;

                                            return {controllers}
                                        })
                                    }}
                                />
                                <Button
                                    className={`bg-red-200 text-red-700 ml-5 text-sm`}
                                    onClick={(e) => {
                                        e.preventDefault();

                                        this.setState((prevState) => {
                                            let controllers = [...prevState.controllers];
                                            controllers.splice(i, 1);

                                            return {controllers};
                                        });
                                    }}
                                >
                                    <FaTrashAlt/>
                                </Button></div>)
                        }
                        <Button
                            className={`bg-pink-200 text-pink-700 mt-5`}
                            onClick={(e) => {
                                e.preventDefault();

                                this.setState((prevState) => {
                                    let controllers = [...prevState.controllers];

                                    controllers.push("");

                                    return {controllers};
                                });
                            }}
                        >
                            Add new controller
                        </Button>


                        <hr className={`my-5`}/>
                        <Button
                            className={`bg-green-200 text-green-700`}
                            onClick={this.finishForm.bind(this)}
                        >
                            Submit
                        </Button>
                    </FormContainer>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    actionGroup: state.actionGroup,
    action: state.action,
});

const mapDispatchToProps = {
    addActionGroupData,
    getActionDatas,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(ActionGroupAdd)
);
