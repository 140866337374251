import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {deleteGuestAccessData, getGuestAccessDatas,} from "../../../redux/modules/GuestAccess/action";

class GuestAccess extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getGuestAccessDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.guestAccess.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const guestAccessColumn = [
            {
                name: "Card",
                path: "card",
                show: true,
                render: (data) => data?.name,
            },
            {
                name: "Guest",
                path: "guest",
                show: true,
                render: (data) => data?.name,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        addData={true}
                        trashBin={true}
                        path={this.props.location.pathname}
                        title={"Guest Access Data"}
                        data={this.props.guestAccess.datas}
                        columns={guestAccessColumn}
                        action={["update", "delete"]}
                        trash={true}
                        deleteAction={(id) => this.props.deleteGuestAccessData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    guestAccess: state.guestAccess,
});

const mapDispatchToProps = {
    getGuestAccessDatas,
    deleteGuestAccessData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(GuestAccess)
);
