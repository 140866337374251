import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {
    getDeletedAcCategoryDatas,
    hardDeleteAcCategoryData,
    restoreAcCategoryData,
} from "../../../redux/modules/AcCategory/action";

class AcCategoryTrash extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getDeletedAcCategoryDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.acCategory.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const acCategoryColumn = [
            {
                name: "Access Category",
                path: "name",
                show: true,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        path={this.props.location.pathname}
                        title={"Access Category Data"}
                        data={this.props.acCategory.datas}
                        columns={acCategoryColumn}
                        trashAction={true}
                        deleteAction={(id) => this.props.hardDeleteAcCategoryData(id)}
                        restoreAction={(id) => this.props.restoreAcCategoryData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    acCategory: state.acCategory,
});

const mapDispatchToProps = {
    getDeletedAcCategoryDatas,
    hardDeleteAcCategoryData,
    restoreAcCategoryData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(AcCategoryTrash)
);
