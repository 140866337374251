import React from "react";
import MainLayout from "../../../hoc/main";
import {Checkbox, Dropdown, FormContainer, Grid, Input, TextArea,} from "../../../components";
import {connect} from "react-redux";
import {addAccessData} from "../../../redux/modules/Access/action";
import {getRequestData} from "../../../redux/modules/Request/action";
import {getAccessCategoryDatas} from "../../../redux/modules/AccessCategory/action";
import {Button} from "../../../components/Button";
import {Redirect} from "react-router-dom";
import queryString from "query-string";

class AccessAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            description: "",
            category: "",
            UNUM: "",
            status: false,
        };
    }

    componentDidMount() {
        this.props.getAccessCategoryDatas();

        if (this.props.location.search !== "") {
            const query = queryString.parse(this.props.location.search);

            this.props.getRequestData(query?.reqid);
        }
    }

    UNSAFE_componentWillReceiveProps(np) {
        if (this.props.location.search !== "") {
            this.setState({
                UNUM: np.request?.data?.UNUM,
            });
        }
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });
    };

    onChangeCheckBox = (e) => {
        const {checked, name} = e.target;
        this.setState({
            [name]: checked,
        });
    };

    finishForm(e) {
        e.preventDefault();
        this.props.addAccessData(this.state);
    }

    render() {
        if (this.props.access?.success) return <Redirect to="/access"/>;
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <FormContainer className={`pt-0`}>
                        <Input
                            name={"UNUM"}
                            label={"Access UNUM"}
                            value={this.state.UNUM}
                            placeholder={"XX:XX:XX:XX:XX"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <Input
                            name={"name"}
                            label={"Access name"}
                            value={this.state.name}
                            placeholder={"Lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <TextArea
                            name={"description"}
                            label={"Description"}
                            value={this.state.description}
                            placeholder={"Lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                            rows={5}
                        />
                        <Dropdown
                            label={"Category"}
                            data={this.props.accessCategory.datas}
                            expectedValue={"id"}
                            display={"name"}
                            name={"category"}
                            value={this.state.category}
                            onChange={this.onChangeValue}
                            placeholder="Choose one category"
                        />
                        <Checkbox
                            label={"Device Status"}
                            checked={this.state.status}
                            name={"status"}
                            onChange={this.onChangeCheckBox}
                        />
                        <hr className={`my-5`}/>
                        <Button
                            className={`bg-green-200 text-green-700`}
                            onClick={this.finishForm.bind(this)}
                        >
                            Submit
                        </Button>
                    </FormContainer>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    access: state.access,
    accessCategory: state.accessCategory,
    request: state.request,
});

const mapDispatchToProps = {
    addAccessData,
    getAccessCategoryDatas,
    getRequestData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(AccessAdd)
);
