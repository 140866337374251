import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {
    getDeletedAcFreonTypeDatas,
    hardDeleteAcFreonTypeData,
    restoreAcFreonTypeData,
} from "../../../redux/modules/AcFreonType/action";

class AcFreonTypeTrash extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getDeletedAcFreonTypeDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.acFreonType.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const acFreonTypeColumn = [
            {
                name: "Access FreonType",
                path: "name",
                show: true,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        path={this.props.location.pathname}
                        title={"Access FreonType Data"}
                        data={this.props.acFreonType.datas}
                        columns={acFreonTypeColumn}
                        trashAction={true}
                        deleteAction={(id) => this.props.hardDeleteAcFreonTypeData(id)}
                        restoreAction={(id) => this.props.restoreAcFreonTypeData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    acFreonType: state.acFreonType,
});

const mapDispatchToProps = {
    getDeletedAcFreonTypeDatas,
    hardDeleteAcFreonTypeData,
    restoreAcFreonTypeData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(AcFreonTypeTrash)
);
