import React from "react";
import {FaSignOutAlt} from "react-icons/fa";

export const SidebarProfile = ({profileName, profilePicture, logoutAction}) => {
    return (
        <div className=" mt-auto sticky z-50 bottom-0 bg-white border-t-2">
            <div className="flex flex-row items-center p-3 ">
                <img
                    src={profilePicture}
                    className={"w-12 h-12 my-5 rounded-full"}
                    alt=""
                />
                <p className={"ml-3 text-sm font-semibold text-gray-600"}>
                    {profileName}
                </p>
            </div>
            <div className="text-gray-600 grid bg-green-700">
                <button onClick={logoutAction}
                        className="text-green-200 transition transition-all hover:bg-green-200 hover:text-green-800 text-center flex items-center justify-center p-3">
                    <FaSignOutAlt/>
                </button>
            </div>
        </div>
    );
};
