import React from "react";
import MainLayout from "../../../hoc/main";
import {Checkbox, Dropdown, FormContainer, Grid, Input,} from "../../../components";
import {connect} from "react-redux";
import {addActionData} from "../../../redux/modules/Action/action";
import {getDeviceData, getDeviceDatas,} from "../../../redux/modules/Device/action";
import {Button} from "../../../components/Button";
import {Redirect} from "react-router-dom";

class ActionAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            pin: [],
            device: "",
            action: "",
            status: false,
        };
    }

    componentDidMount() {
        this.props.getDeviceDatas();
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });

        if (name === "device") {
            this.props.getDeviceData(value);
        }
    };

    onChangeCheckBox = (e) => {
        const {checked, name} = e.target;
        this.setState({
            [name]: checked,
        });
    };

    onPinChange = (index, e) => {
        let pin = this.state.pin.slice();

        pin[index] = e.target.value;

        console.log(pin);

        this.setState({
            pin: pin,
        });
    };

    finishForm(e) {
        e.preventDefault();
        this.props.addActionData(this.state);
    }

    render() {
        if (this.props.action?.success) return <Redirect to="/action"/>;
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <FormContainer className={`pt-0`}>
                        <Input
                            name={"name"}
                            label={"Action name"}
                            value={this.state.name}
                            placeholder={"Lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <Dropdown
                            label={"Action"}
                            data={[
                                {id: 1, name: "Switch", pin: 1},
                                {id: 1, name: "RGB", pin: 1},
                                {id: 1, name: "Brightness", pin: 1},
                            ]}
                            expectedValue={"name"}
                            display={"name"}
                            name={"action"}
                            value={this.state.action}
                            onChange={this.onChangeValue}
                            placeholder="Choose one action to control"
                        />
                        <Dropdown
                            label={"Device"}
                            data={this.props.device.datas}
                            expectedValue={"id"}
                            display={"name"}
                            name={"device"}
                            value={this.state.device}
                            onChange={this.onChangeValue}
                            placeholder="Choose one device"
                        />

                        {this.state.device !== "" ? (
                            <Dropdown
                                label={"Pin"}
                                data={this.props.device?.data?.type?.pins?.digital}
                                name={"pin"}
                                value={this.state.pin}
                                onChange={this.onPinChange.bind(this, 0)}
                                placeholder="Choose one pin"
                            />
                        ) : null}
                        <Checkbox
                            label={"Action Status"}
                            checked={this.state.status}
                            name={"status"}
                            onChange={this.onChangeCheckBox}
                        />
                        <hr className={`my-5`}/>
                        <Button
                            className={`bg-green-200 text-green-700`}
                            onClick={this.finishForm.bind(this)}
                        >
                            Submit
                        </Button>
                    </FormContainer>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    action: state.action,
    device: state.device,
});

const mapDispatchToProps = {
    addActionData,
    getDeviceDatas,
    getDeviceData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(ActionAdd)
);
