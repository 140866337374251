import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {
    getDeletedActionGroupDatas,
    hardDeleteActionGroupData,
    restoreActionGroupData,
} from "../../../redux/modules/ActionGroup/action";

class ActionGroupTrash extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getDeletedActionGroupDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.actionGroup.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const actionGroupColumn = [
            {
                name: "Group",
                path: "name",
                show: true,
            },
            {
                name: "Action",
                path: "action",
                show: true,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        path={this.props.location.pathname}
                        title={"ActionGroup Category Data"}
                        data={this.props.actionGroup.datas}
                        columns={actionGroupColumn}
                        trashAction={true}
                        deleteAction={(id) => this.props.hardDeleteActionGroupData(id)}
                        restoreAction={(id) => this.props.restoreActionGroupData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    actionGroup: state.actionGroup,
});

const mapDispatchToProps = {
    getDeletedActionGroupDatas,
    hardDeleteActionGroupData,
    restoreActionGroupData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(ActionGroupTrash)
);
