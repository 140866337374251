import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {deleteAcData, getAcDatas} from "../../../redux/modules/Ac/action";

class Ac extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getAcDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.ac.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const acColumn = [
            {
                name: "Ac",
                path: "name",
                show: true,
            },
            {
                name: "Category",
                path: "category",
                show: true,
                render: (data) => data?.name,
            },
            {
                name: "Freon Type",
                path: "freonType",
                show: true,
                render: (data) => data?.name,
            },
            {
                name: "Brand",
                path: "brand",
                show: true,
                render: (data) => data?.name,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        addData={true}
                        trashBin={true}
                        path={this.props.location.pathname}
                        title={"Ac Data"}
                        data={this.props.ac.datas}
                        columns={acColumn}
                        action={["update", "delete"]}
                        trash={true}
                        deleteAction={(id) => this.props.deleteAcData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ac: state.ac,
});

const mapDispatchToProps = {
    getAcDatas,
    deleteAcData,
};

export default MainLayout(connect(mapStateToProps, mapDispatchToProps)(Ac));
