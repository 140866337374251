import {acCategoryTypes} from "./type";
import {BaseReducer} from "../../reducers/base";

const types = acCategoryTypes;

const initialState = {
    datas: [],
    data: {},
    isLoading: false,
    success: false,
    msg: {},
};

const acCategoryReducer = (state = initialState, action) => {
    const baseClass = new BaseReducer(types, state, action);

    return baseClass.baseReducer();
};

export default acCategoryReducer;
