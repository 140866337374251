import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {deleteDeviceData, getDeviceDatas,} from "../../../redux/modules/Device/action";

class Device extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getDeviceDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.device.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const deviceColumn = [
            {
                name: "Device",
                path: "name",
                show: true,
            },
            {
                name: "Description",
                path: "description",
                show: true,
            },
            {
                name: "Category",
                path: "type",
                show: true,
                render: (data) => data?.name,
            },
            {
                name: "Status",
                path: "status",
                show: true,
                render: (data) => (data ? "Active" : "Not Active"),
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        addData={true}
                        trashBin={true}
                        path={this.props.location.pathname}
                        title={"Device Data"}
                        data={this.props.device.datas}
                        columns={deviceColumn}
                        action={["update", "delete"]}
                        trash={true}
                        deleteAction={(id) => this.props.deleteDeviceData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    device: state.device,
});

const mapDispatchToProps = {
    getDeviceDatas,
    deleteDeviceData,
};

export default MainLayout(connect(mapStateToProps, mapDispatchToProps)(Device));
