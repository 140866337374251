import React from "react";
import MainLayout from "../../../hoc/main";
import {connect} from "react-redux";
import {ImSwitch} from "react-icons/im";
import {MdBrightness6} from "react-icons/md";
import {getDeviceDatas} from "../../../redux/modules/Device/action";
import {editActionData, getActionDatas,} from "../../../redux/modules/Action/action";
import {Grid} from "../../../components";
import {mqttClient} from "../../../config/mqtt";
import {FaArrowRight, FaRegLightbulb} from "react-icons/fa";
import {ButtonIcon} from "../../../components/Button";

class Controller extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            action: [],
            device: [],
        };

        this.mqttClient = mqttClient;

        this.mqttClient.subscribe("sthira/controller/+/setvalue");

        this.mqttClient.on("message", (topic, payload) => {
            let message = payload.toString();
            const myTopic = topic.split("/");

            if (myTopic[1] === "controller" && myTopic.includes("setvalue")) {
                let switcher = JSON.parse(message);
                this.setState((prevState) => {
                    let prevActions = [...prevState.action];

                    let actionIndex = prevActions.findIndex(
                        (x) =>
                            x?.device?.UNUM === myTopic[2] && x?.pin[0] === switcher?.pin[0]
                    );

                    prevActions[actionIndex].state = switcher.value;

                    return {action: prevActions};
                });
            }
        });
    }

    componentDidMount() {
        this.props.getActionDatas();
        this.props.getDeviceDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            action: np.action.datas,
            device: np.device.datas,
        });
    }

    render() {
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    {this.state.device?.map((dev, i) => {
                        return (
                            <details
                                key={i}
                                className={"cursor-default p-5  shadow rounded-lg"}
                            >
                                <summary className={"font-medium text-gray-500"}>
                                    {dev?.name}
                                </summary>
                                <Grid className={"grid-cols-2 md:grid-cols-4 xl:grid-cols-6"}>
                                    {this.state.action?.map(
                                        (ac, i) =>
                                            ac?.device?.id === dev?.id &&
                                            (ac?.action === "Switch"
                                                ? this.switchController(i, ac, dev)
                                                : ac?.action === "RGB"
                                                    ? this.rgbContoller(i, ac, dev)
                                                    : this.brightnessController(i, ac, dev))
                                    )}
                                </Grid>
                            </details>
                        );
                    })}
                </Grid>
            </div>
        );
    }

    brightnessController(i, ac, dev) {
        return (
            <div
                key={i}
                className={`flex flex-col justify-between shadow p-5 text-gray-400 rounded-lg`}
            >
                <p className={"text-gray-400"}>
                    <MdBrightness6/>
                </p>
                <div className={"mt-5"}>
                    <p className={"text-gray-400 text-sm md:text-md"}>{ac?.name}</p>
                    <div className={"mt-3 flex flex-row"}>
                        <input
                            onChange={(e) => {
                                e.preventDefault();
                                let value = e.target.value;
                                this.setState((prevState) => {
                                    let prevActions = [...prevState.action];
                                    prevActions[i].state[0] = value;
                                    return {action: prevActions};
                                });
                            }}
                            type="number"
                            min="5"
                            max="255"
                            value={ac?.state?.length === 0 ? 255 : ac?.state[0]}
                            className={
                                "appearance-none border rounded py-2 px-3 text-gray-700 focus:bg-gray-100 focus:outline-none text-sm disabled:bg-gray-400"
                            }
                        />
                        <ButtonIcon
                            icon={<FaArrowRight/>}
                            className={"ml-2 bg-green-200 text-green-700"}
                            onClick={(e) => {
                                e.preventDefault();
                                this.mqttClient.publish(
                                    `sthira/controller/${dev.UNUM}/trigger`,
                                    JSON.stringify({
                                        device: "brightness",
                                        pin: [ac?.pin[0]],
                                        value: [
                                            this.state.action[i]?.state.length === 0
                                                ? "255"
                                                : this.state.action[i]?.state[0],
                                        ],
                                    })
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    rgbContoller(i, ac, dev) {
        return (
            <div
                key={i}
                className={`flex flex-col justify-between shadow p-5 text-gray-400 rounded-lg`}
            >
                <p className={"text-gray-400"}>
                    <FaRegLightbulb/>
                </p>
                <div className={"mt-5"}>
                    <p className={"text-gray-400 text-sm md:text-md"}>{ac?.name}</p>
                    <div className={"mt-3 flex flex-row"}>
                        {[
                            "red-500",
                            "yellow-300",
                            "green-500",
                            "blue-300",
                            "purple-500",
                            "gray-100",
                            "gray-900",
                        ].map((color, i) => (
                            <div
                                className={`cursor-pointer mr-2 w-7 h-7 bg-${color}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.mqttClient.publish(
                                        `sthira/controller/${dev.UNUM}/trigger`,
                                        JSON.stringify({
                                            device: "rgb",
                                            pin: [ac?.pin[0]],
                                            value: [String(i + 1)],
                                        })
                                    );
                                }}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    switchController(i, ac, dev) {
        return (
            <div
                key={i}
                className={`cursor-pointer flex flex-col justify-between shadow p-5 text-gray-400 rounded-lg ${ac?.state[0] === "1" ? "hover:bg-gray-100" : "hover:bg-green-100"
                }`}
                onClick={(e) => {
                    e.preventDefault();
                    this.mqttClient.publish(
                        `sthira/controller/${dev.UNUM}/trigger`,
                        JSON.stringify({
                            device: "switch",
                            pin: [ac?.pin[0]],
                            value: [ac?.state[0] === "1" ? "0" : "1"],
                        })
                    );
                }}
            >
                <p
                    className={`${ac?.state[0] === "1" ? "text-green-500" : "text-gray-400"
                    }`}
                >
                    <ImSwitch/>
                </p>
                <div className={"mt-5"}>
                    <p className={"text-gray-400 text-sm md:text-md"}>{ac?.name}</p>
                    <p
                        className={`mt-2 font-bold ${ac?.state[0] === "1" ? "text-green-500" : "text-gray-400"
                        }`}
                    >
                        {ac?.state[0] === "1" ? "ON" : "OFF"}
                    </p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    device: state.device,
    action: state.action,
});

const mapDispatchToProps = {
    getDeviceDatas,
    getActionDatas,
    editActionData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(Controller)
);
