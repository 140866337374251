import moment from 'moment';

export const Input = ({
                          name,
                          label,
                          value,
                          onChange,
                          className,
                          placeholder,
                          disabled, type
                      }) => {
    return (
        <div className={""}>
            {label ? (
                <label htmlFor={name} className={"block font-medium w-1/5 mb-2 mt-5"}>
                    {label}
                </label>
            ) : null}

            <input
                className={`appearance-none border rounded py-2 px-3 text-gray-700 focus:bg-gray-100 focus:outline-none text-sm ${className} disabled:bg-gray-400`}
                name={name || ""}
                type={type || "text"}
                placeholder={placeholder}
                value={value || ""}
                onChange={onChange}
                disabled={disabled}
            />
        </div>
    );
};

export const Datepicker = ({
                               name,
                               label,
                               value,
                               onChange,
                               className,
                               placeholder,
                               disabled,
                           }) => {
    return (
        <div className={""}>
            {label ? (
                <label htmlFor={name} className={"block font-medium w-1/5 mb-2 mt-5"}>
                    {label}
                </label>
            ) : null}

            <input
                className={`appearance-none border rounded py-2 px-3 text-gray-700 focus:bg-gray-100 focus:outline-none text-sm ${className} disabled:bg-gray-400`}
                name={name || ""}
                type="date"
                placeholder={placeholder}
                value={value || ""}
                onChange={onChange}
                disabled={disabled}
            />
        </div>
    );
};

export const Timepicker = ({
                               name,
                               label,
                               value,
                               onChange,
                               className,
                               placeholder,
                               disabled,
                           }) => {
    return (
        <div className={""}>
            {label ? (
                <label htmlFor={name} className={"block font-medium w-1/5 mb-2 mt-5"}>
                    {label}
                </label>
            ) : null}

            <input
                className={`appearance-none border rounded py-2 px-3 text-gray-700 focus:bg-gray-100 focus:outline-none text-sm ${className} disabled:bg-gray-400`}
                name={name || ""}
                type="time"
                placeholder={placeholder}
                value={moment(value).format('HH:mm') || ""}
                onChange={onChange}
                disabled={disabled}
            />
        </div>
    );
};

export const TextArea = ({
                             name,
                             label,
                             className,
                             placeholder,
                             value,
                             onChange,
                             onFocus,
                             rows,
                             cols,
                         }) => {
    return (
        <div>
            {label ? (
                <label htmlFor={name} className={"block font-medium w-1/5 mb-2 mt-5"}>
                    {label}
                </label>
            ) : null}

            <textarea
                type={"text"}
                name={name}
                className={`appearance-none border rounded py-2 px-3 text-gray-700 focus:bg-gray-100 focus:outline-none text-sm ${className} disabled:bg-gray-400`}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                rows={rows}
                cols={cols}
            />
        </div>
    );
};
