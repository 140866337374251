import {presentationTypes} from "./type";
import {BaseAction} from "../../actions/base";

const types = presentationTypes;

const path = `iot/presentation`;

const base = new BaseAction(types);

export const getPresentationDatas = () => (dispatch) => {
    return base.getDatas(path, dispatch);
};

export const getDeletedPresentationDatas = () => (dispatch) => {
    return base.getDatas(`${path}/deleted`, dispatch);
};

export const getPresentationData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}`, dispatch);
};

export const getDeletedPresentationData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}/deleted`, dispatch);
};

export const addPresentationData = (newData) => (dispatch) => {
    return base.addData(path, dispatch, newData);
};

export const editPresentationData = (id, newData) => (dispatch) => {
    return base.editData(`${path}/${id}`, dispatch, newData);
};

export const deletePresentationData = (id) => (dispatch) => {
    return base.deleteData(`${path}/${id}`, dispatch, id);
};

export const restorePresentationData = (id) => (dispatch) => {
    return base.restoreData(`${path}/${id}/restore`, dispatch, id);
};

export const hardDeletePresentationData = (id) => (dispatch) => {
    return base.hardDeleteData(`${path}/${id}/confirmdelete`, dispatch, id);
};
