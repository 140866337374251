import {acBrandTypes} from "./type";
import {BaseAction} from "../../actions/base";

const types = acBrandTypes;

const path = `iot/ac-brand`;

const base = new BaseAction(types);

export const getAcBrandDatas = () => (dispatch) => {
    return base.getDatas(path, dispatch);
};

export const getDeletedAcBrandDatas = () => (dispatch) => {
    return base.getDatas(`${path}/deleted`, dispatch);
};

export const getAcBrandData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}`, dispatch);
};

export const getDeletedAcBrandData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}/deleted`, dispatch);
};

export const addAcBrandData = (newData) => (dispatch) => {
    return base.addData(path, dispatch, newData);
};

export const editAcBrandData = (id, newData) => (dispatch) => {
    return base.editData(`${path}/${id}`, dispatch, newData);
};

export const deleteAcBrandData = (id) => (dispatch) => {
    return base.deleteData(`${path}/${id}`, dispatch, id);
};

export const restoreAcBrandData = (id) => (dispatch) => {
    return base.restoreData(`${path}/${id}/restore`, dispatch, id);
};

export const hardDeleteAcBrandData = (id) => (dispatch) => {
    return base.hardDeleteData(`${path}/${id}/confirmdelete`, dispatch, id);
};
