import {logAccessTypes} from "./type";
import {BaseAction} from "../../actions/base";

const types = logAccessTypes;

const path = `iot/log/access`;

const base = new BaseAction(types);

export const getLogAccessDatas = () => (dispatch) => {
    return base.getDatas(path, dispatch);
};
