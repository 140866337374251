import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {getDeletedAccessDatas, hardDeleteAccessData, restoreAccessData,} from "../../../redux/modules/Access/action";

class AccessTrash extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getDeletedAccessDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.access.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const accessColumn = [
            {
                name: "Access",
                path: "name",
                show: true,
            },
            {
                name: "Description",
                path: "description",
                show: true,
            },
            {
                name: "Category",
                path: "category",
                show: true,
                render: (data) => data?.name,
            },
            {
                name: "Status",
                path: "status",
                show: true,
                render: (data) => (data ? "Active" : "Not Active"),
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        path={this.props.location.pathname}
                        title={"Access Category Data"}
                        data={this.props.access.datas}
                        columns={accessColumn}
                        trashAction={true}
                        deleteAction={(id) => this.props.hardDeleteAccessData(id)}
                        restoreAction={(id) => this.props.restoreAccessData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    access: state.access,
});

const mapDispatchToProps = {
    getDeletedAccessDatas,
    hardDeleteAccessData,
    restoreAccessData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(AccessTrash)
);
