import React from "react";
import MainLayout from "../../../hoc/main";
import {Checkbox, Dropdown, FormContainer, Grid, Input, TextArea,} from "../../../components";
import {connect} from "react-redux";
import {addDeviceData} from "../../../redux/modules/Device/action";
import {getTypeDatas} from "../../../redux/modules/Type/action";
import {Button} from "../../../components/Button";
import {Redirect} from "react-router-dom";

class DeviceAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            description: "",
            type: "",
            UNUM: "",
            status: false,
        };
    }

    componentDidMount() {
        this.props.getTypeDatas();
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });
    };

    onChangeCheckBox = (e) => {
        const {checked, name} = e.target;
        this.setState({
            [name]: checked,
        });
    };

    finishForm(e) {
        e.preventDefault();
        this.props.addDeviceData(this.state);
    }

    render() {
        if (this.props.device?.success) return <Redirect to="/device"/>;
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <FormContainer className={`pt-0`}>
                        <Input
                            name={"UNUM"}
                            label={"Device UNUM"}
                            value={this.state.UNUM}
                            placeholder={"XX:XX:XX:XX:XX"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <Input
                            name={"name"}
                            label={"Device name"}
                            value={this.state.name}
                            placeholder={"Lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <TextArea
                            name={"description"}
                            label={"Description"}
                            value={this.state.description}
                            placeholder={"Lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                            rows={5}
                        />
                        <Dropdown
                            label={"Category"}
                            data={this.props.type.datas}
                            expectedValue={"id"}
                            display={"name"}
                            name={"type"}
                            value={this.state.type}
                            onChange={this.onChangeValue}
                            placeholder="Choose one type"
                        />
                        <Checkbox
                            label={"Device Status"}
                            checked={this.state.status}
                            name={"status"}
                            onChange={this.onChangeCheckBox}
                        />
                        <hr className={`my-5`}/>
                        <Button
                            className={`bg-green-200 text-green-700`}
                            onClick={this.finishForm.bind(this)}
                        >
                            Submit
                        </Button>
                    </FormContainer>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    device: state.device,
    type: state.type,
});

const mapDispatchToProps = {
    addDeviceData,
    getTypeDatas,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(DeviceAdd)
);
