import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {deleteTypeData, getTypeDatas,} from "../../../redux/modules/Type/action";

class Type extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getTypeDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.type.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const typeColumn = [
            {
                name: "Type",
                path: "name",
                show: true,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        addData={true}
                        trashBin={true}
                        path={this.props.location.pathname}
                        title={"Type Data"}
                        data={this.props.type.datas}
                        columns={typeColumn}
                        action={["update", "delete"]}
                        trash={true}
                        deleteAction={(id) => this.props.deleteTypeData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    type: state.type,
});

const mapDispatchToProps = {
    getTypeDatas,
    deleteTypeData,
};

export default MainLayout(connect(mapStateToProps, mapDispatchToProps)(Type));
