import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button} from "../../components/Button";
import {login} from "../../redux/modules/Auth/action";
import {Redirect} from "react-router-dom";

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nip: "",
            password: "",
        };
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });
    };

    finishForm(e) {
        e.preventDefault();
        this.props.login(this.state);
    }

    render() {
        if (localStorage.getItem("nekot")) return <Redirect to="/"/>;
        return (
            <div className={"h-screen flex justify-center"}>
                <div className="mt-32 text-center">
                    <h3 className="text-green-700 font-bold text-xl">Login IoT Console</h3>
                    <div className="bg-green-300 mt-5 p-5">
                        <div className="text-left">
                            <label className={"font-medium text-green-700"}>Username</label>
                            <input
                                className={`block mt-2 appearance-none border py-2 px-3 text-gray-700 focus:bg-gray-100 focus:outline-none text-sm disabled:bg-gray-400`}
                                style={{width: "300px"}}
                                name={"nip"}
                                type={"text"}
                                placeholder={"123"}
                                value={this.state.nip}
                                onChange={this.onChangeValue}
                                disabled={false}
                            />
                        </div>
                        <div className="text-left mt-5">
                            <label className={"font-medium text-green-700"}>Password</label>
                            <input
                                className={`block mt-2 appearance-none border py-2 px-3 text-gray-700 focus:bg-gray-100 focus:outline-none text-sm disabled:bg-gray-400`}
                                style={{width: "300px"}}
                                name={"password"}
                                type={"password"}
                                placeholder={"Kata sandi"}
                                value={this.state.password}
                                onChange={this.onChangeValue}
                                disabled={false}
                            />
                        </div>
                        <div className="text-left mt-5">
                            <Button
                                className={`bg-green-900 w-full text-green-200`}
                                onClick={this.finishForm.bind(this)}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, {login}
)(Index);