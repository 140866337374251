import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {
    getDeletedRequestDatas,
    hardDeleteRequestData,
    restoreRequestData,
} from "../../../redux/modules/Request/action";

class AccessRequestTrash extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getDeletedRequestDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.request.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const requestColumn = [
            {
                name: "Number",
                path: "UNUM",
                show: true,
            },
        ];

        const filteredAccessOnly = this.props.request?.datas.filter(
            (d) => d.requestCategory === "Access"
        );

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        path={this.props.location.pathname}
                        title={"Request Category Data"}
                        data={filteredAccessOnly}
                        columns={requestColumn}
                        trashAction={true}
                        deleteAction={(id) => this.props.hardDeleteRequestData(id)}
                        restoreAction={(id) => this.props.restoreRequestData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    request: state.request,
});

const mapDispatchToProps = {
    getDeletedRequestDatas,
    hardDeleteRequestData,
    restoreRequestData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(AccessRequestTrash)
);
