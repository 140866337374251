import React from "react";
import {data} from "../../data/menu";
import {SidebarMenu} from "./Menu";
import {SidebarTitle} from "./Title";
import {FaStream} from "react-icons/fa";
import {SidebarProfile} from "./Profile";

export const Sidebar = ({
                            menuOpened,
                            closeMenu,
                            profileName,
                            profilePicture,
                            pathName,
                            logoutAction
                        }) => {
    return (
        <aside
            className={`transition duration-100 ease-in-out ${
                !menuOpened ? "-left-72" : "-left-0"
            }  shadow-md fixed xl:-left-0 xl:flex justify-between flex-col h-full w-60 bg-white overflow-y-scroll z-20`}
        >
            {/* Header title web start */}
            <div className="mt-auto sticky z-50 top-0 bg-white flex justify-between align-items xl:block px-4">
                <button
                    className={
                        "text-green-800 text-bold xl:hidden appearance-none focus:outline-none"
                    }
                    onClick={closeMenu}
                >
                    <FaStream/>
                </button>
                <SidebarTitle title="Console IoT"/>
            </div>
            {/* Header title web end */}

            <div className="">
                {/* Menu list start */}
                <SidebarMenu data={data} activeMenu={pathName}/>
                {/* Menu list end */}
            </div>

            {/* Profile section start */}
            <SidebarProfile
                logoutAction={logoutAction}
                profileName={profileName}
                profilePicture={profilePicture}
            />
            {/* Profile section end */}
        </aside>
    );
};
