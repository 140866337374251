import {toast} from "react-toastify";
import axios from "../../config/axios";

export class BaseAction {
    constructor(types) {
        this.types = types;
    }

    getDatas = (path, dispatch) => {
        dispatch({
            type: this.types.loading,
        });

        return axios()
            .get(path)
            .then((res) => {
                const {data} = res.data;

                dispatch({
                    type: this.types.loadedMultiple,
                    payload: data,
                });
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.errors[0]?.message}`);
                return console.log(err?.response?.data?.errors[0]?.message);
            });
    };

    getData = (path, dispatch) => {
        dispatch({
            type: this.types.loading,
        });

        return axios()
            .get(path)
            .then((res) => {
                const {data} = res.data;

                dispatch({
                    type: this.types.loadedOne,
                    payload: data,
                });
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.errors[0]?.message}`);
                return console.log(err?.response?.data?.errors[0]?.message);
            });
    };

    addData = (path, dispatch, newData) => {
        return axios()
            .post(path, newData)
            .then((res) => {
                const {data} = res.data;
                dispatch({
                    type: this.types.success,
                    payload: data,
                });
                toast.success(`Data berhasil ditambahkan`);
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.errors[0]?.message}`);
                return console.log(err?.response?.data?.errors[0]?.message);
            });
    };

    editData = (path, dispatch, newData, callback) => {
        console.log(path);
        console.log(newData);
        return axios()
            .put(path, newData)
            .then((res) => {
                // console.log(room);
                const {data} = res.data;
                dispatch({
                    type: this.types.success,
                    payload: data,
                });

                toast.success(`Data berhasil diubah`);
                if (callback) {
                    return callback(data);
                }
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.errors[0]?.message}`);
                return console.log(err?.response?.data?.errors[0]?.message);
            });
    };

    deleteData = (path, dispatch, id) => {
        return axios()
            .delete(path)
            .then((res) => {
                dispatch({
                    type: this.types.delete,
                    payload: id,
                });
                toast.success(`Data berhasil dihapus`);
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.errors[0]?.message}`);
                return console.log(err?.response?.data?.errors[0]?.message);
            });
    };

    restoreData = (path, dispatch, id) => {
        return axios()
            .get(path)
            .then((res) => {
                dispatch({
                    type: this.types.restore,
                    payload: id,
                });
                toast.success(`Data berhasil dikembalikan`);
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.errors[0]?.message}`);
                return console.log(err?.response?.data?.errors[0]?.message);
            });
    };

    hardDeleteData = (path, dispatch, id) => {
        return axios()
            .get(path)
            .then((res) => {
                dispatch({
                    type: this.types.hardDelete,
                    payload: id,
                });
                toast.success(`Data berhasil dihapus`);
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.errors[0]?.message}`);
                return console.log(err?.response?.data?.errors[0]?.message);
            });
    };
}
