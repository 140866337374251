import {employeeTypes} from "./type";
import {BaseAction} from "../../actions/base";

const types = employeeTypes;

const path = `iot/employee/`;

const base = new BaseAction(types);

export const getEmployeeDatas = () => (dispatch) => {
    return base.getDatas(path, dispatch);
};

export const getEmployeeData = (id) => (dispatch) => {
    return base.getData(path + id, dispatch);
};

export const addEmployeeData = () => (dispatch) => {
    return base.addData(path, dispatch);
};

export const editEmployeeData = (id, newData) => (dispatch) => {
    return base.editData(path + id, dispatch, newData);
};

export const deleteEmployeeData = (id) => (dispatch) => {
    return base.deleteData(path + id, dispatch, id);
};
