import React from "react";
import MainLayout from "../../../hoc/main";
import {Dropdown, FormContainer, Grid, Input} from "../../../components";
import {connect} from "react-redux";
import {editAcData, getAcData} from "../../../redux/modules/Ac/action";
import {getAcCategoryDatas} from "../../../redux/modules/AcCategory/action";
import {getAcBrandDatas} from "../../../redux/modules/AcBrand/action";
import {getAcFreonTypeDatas} from "../../../redux/modules/AcFreonType/action";
import {Button} from "../../../components/Button";
import {Redirect} from "react-router-dom";

class AcEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            category: "",
            brand: "",
            freonType: "",
            url: "",
        };
    }

    componentDidMount() {
        this.props.getAcCategoryDatas();
        this.props.getAcBrandDatas();
        this.props.getAcFreonTypeDatas();
        this.props.getAcData(this.props.match.params.id);
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            name: np.ac.data.name,
            category: np.ac.data?.category?.id,
            freonType: np.ac.data?.freonType?.id,
            brand: np.ac.data?.brand?.id,
            url: np.ac.data.url,
        });
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });
    };

    onChangeCheckBox = (e) => {
        const {checked, name} = e.target;
        this.setState({
            [name]: checked,
        });
    };

    finishForm(e) {
        e.preventDefault();
        this.props.editAcData(this.props.match.params.id, this.state);
    }

    render() {
        if (this.props.ac?.success) return <Redirect to="/ac"/>;
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <FormContainer className={`pt-0`}>
                        <Input
                            name={"name"}
                            label={"Ac name"}
                            value={this.state.name}
                            placeholder={"Lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <Input
                            name={"url"}
                            label={"Ac url"}
                            value={this.state.url}
                            placeholder={"https://google.com"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />

                        <hr className={"my-5"}/>
                        <Dropdown
                            label={"Category"}
                            data={this.props.acCategory.datas}
                            expectedValue={"id"}
                            display={"name"}
                            name={"category"}
                            value={this.state.category}
                            onChange={this.onChangeValue}
                            placeholder="Choose one category"
                            className={`mb-5`}
                        />
                        <Dropdown
                            label={"Brand"}
                            data={this.props.acBrand.datas}
                            expectedValue={"id"}
                            display={"name"}
                            name={"brand"}
                            value={this.state.brand}
                            onChange={this.onChangeValue}
                            placeholder="Choose one brand"
                            className={`mb-5`}
                        />
                        <Dropdown
                            label={"Freon Type"}
                            data={this.props.acFreonType.datas}
                            expectedValue={"id"}
                            display={"name"}
                            name={"freonType"}
                            value={this.state.freonType}
                            onChange={this.onChangeValue}
                            placeholder="Choose one freon type"
                            className={`mb-5`}
                        />
                        <hr className={`my-5`}/>
                        <Button
                            className={`bg-green-200 text-green-700`}
                            onClick={this.finishForm.bind(this)}
                        >
                            Submit
                        </Button>
                    </FormContainer>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ac: state.ac,
    acCategory: state.acCategory,
    acBrand: state.acBrand,
    acFreonType: state.acFreonType,
});

const mapDispatchToProps = {
    editAcData,
    getAcCategoryDatas,
    getAcBrandDatas,
    getAcFreonTypeDatas,
    getAcData,
};

export default MainLayout(connect(mapStateToProps, mapDispatchToProps)(AcEdit));
