import React from "react";
import {FaAt, FaAtom, FaFingerprint, FaUsers} from "react-icons/fa";
import MainLayout from "../../../hoc/main";
import {Cardlytics, Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {getAccessDatas} from "../../../redux/modules/Access/action";
import {getLogAccessDatas} from "../../../redux/modules/LogAccess/action";
import {getEmployeeDatas} from "../../../redux/modules/Employee/action";
import {getActionDatas} from "../../../redux/modules/Action/action";
import {getRequestDatas} from "../../../redux/modules/Request/action";

class Dashboard extends React.Component {
    componentDidMount() {
        this.props.getAccessDatas();
        this.props.getLogAccessDatas();
        this.props.getEmployeeDatas();
        this.props.getActionDatas();
        this.props.getRequestDatas();
    }

    render() {
        const employeeColumn = [
            {
                name: "Employee Name",
                path: "name",
                show: true,
            },
            {
                name: "NIP",
                path: "nip",
                show: true,
            },
        ];

        const logAccessColumn = [
            {
                name: "Employee Name",
                path: "employee",
                show: true,
                render: (data) => data?.name,
            },
            {
                name: "Access Name",
                path: "access",
                show: true,
                render: (data) => data?.name,
            },
        ];
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                {/* Analytic cards */}
                <Grid className="md:grid-cols-2 xl:grid-cols-4">
                    {/* Cards */}
                    <Cardlytics
                        title="Employees"
                        data={this.props.employee.datas.length}
                        unit={"Users"}
                        icon={<FaUsers/>}
                        color={"red"}
                    />

                    <Cardlytics
                        title="Accesses"
                        data={this.props.access.datas.length}
                        unit={"Items"}
                        icon={<FaFingerprint/>}
                        color={"blue"}
                    />

                    <Cardlytics
                        title="Actions"
                        data={this.props.action.datas.length}
                        unit={"Items"}
                        icon={<FaAtom/>}
                        color={"green"}
                    />

                    <Cardlytics
                        title="Requests"
                        data={this.props.request.datas.length}
                        test={this.props.request.datas}
                        unit={"Items"}
                        icon={<FaAt/>}
                        color={"yellow"}
                    />
                </Grid>

                <Grid>
                    <TableData
                        title={"Employee Data"}
                        data={this.props.employee.datas}
                        columns={employeeColumn}
                        maxData={5}
                    />
                </Grid>

                <Grid>
                    <TableData
                        title={"Last Controlled Access"}
                        data={this.props.logAccess.datas}
                        columns={logAccessColumn}
                        maxData={5}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    access: state.access,
    logAccess: state.logAccess,
    employee: state.employee,
    action: state.action,
    request: state.request,
});

const mapDispatchToProps = {
    getAccessDatas,
    getLogAccessDatas,
    getEmployeeDatas,
    getActionDatas,
    getRequestDatas,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
