import {typeTypes} from "./type";
import {BaseAction} from "../../actions/base";

const types = typeTypes;

const path = `iot/device-category`;

const base = new BaseAction(types);

export const getTypeDatas = () => (dispatch) => {
    return base.getDatas(path, dispatch);
};

export const getDeletedTypeDatas = () => (dispatch) => {
    return base.getDatas(`${path}/deleted`, dispatch);
};

export const getTypeData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}`, dispatch);
};

export const getDeletedTypeData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}/deleted`, dispatch);
};

export const addTypeData = (newData) => (dispatch) => {
    return base.addData(path, dispatch, newData);
};

export const editTypeData = (id, newData) => (dispatch) => {
    return base.editData(`${path}/${id}`, dispatch, newData);
};

export const deleteTypeData = (id) => (dispatch) => {
    return base.deleteData(`${path}/${id}`, dispatch, id);
};

export const restoreTypeData = (id) => (dispatch) => {
    return base.restoreData(`${path}/${id}/restore`, dispatch, id);
};

export const hardDeleteTypeData = (id) => (dispatch) => {
    return base.hardDeleteData(`${path}/${id}/confirmdelete`, dispatch, id);
};
