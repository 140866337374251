import {guestCardTypes} from "./type";
import {BaseAction} from "../../actions/base";

const types = guestCardTypes;

const path = `iot/guest-access`;

const base = new BaseAction(types);

export const getGuestCardDatas = () => (dispatch) => {
    return base.getDatas(path, dispatch);
};

export const getDeletedGuestCardDatas = () => (dispatch) => {
    return base.getDatas(`${path}/deleted`, dispatch);
};

export const getGuestCardData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}`, dispatch);
};

export const getDeletedGuestCardData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}/deleted`, dispatch);
};

export const addGuestCardData = (newData) => (dispatch) => {
    return base.addData(path, dispatch, newData);
};

export const editGuestCardData = (id, newData) => (dispatch) => {
    return base.editData(`${path}/${id}`, dispatch, newData);
};

export const deleteGuestCardData = (id) => (dispatch) => {
    return base.deleteData(`${path}/${id}`, dispatch, id);
};

export const restoreGuestCardData = (id) => (dispatch) => {
    return base.restoreData(`${path}/${id}/restore`, dispatch, id);
};

export const hardDeleteGuestCardData = (id) => (dispatch) => {
    return base.hardDeleteData(`${path}/${id}/confirmdelete`, dispatch, id);
};
