import React from "react";
import MainLayout from "../../../hoc/main";
import {FormContainer, Grid, Input} from "../../../components";
import {connect} from "react-redux";
import {editTypeData, getTypeData} from "../../../redux/modules/Type/action";
import {Button} from "../../../components/Button";
import {Redirect} from "react-router-dom";

class TypeEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            pins: {
                digital: [],
                analog: [],
            },
            digital: 0,
            analog: 0,
        };
    }

    componentDidMount() {
        this.props.getTypeData(this.props.match.params.id);
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            name: np.type.data.name,
            analog: np.type.data.pins?.analog.length,
            digital: np.type.data.pins?.digital.length,
        });
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });
    };

    onChangeCheckBox = (e) => {
        const {checked, name} = e.target;
        this.setState({
            [name]: checked,
        });
    };

    async finishForm(e) {
        e.preventDefault();
        let pindigital = [];
        let pinanalog = [];

        for (let i = 1; i <= this.state.digital; i++) {
            await pindigital.push(i.toString());
        }
        for (let i = 1; i <= this.state.analog; i++) {
            await pinanalog.push(i.toString());
        }

        this.setState((prevState) => {
            let pins = Object.assign({}, prevState.pins);
            pins.digital = pindigital;
            pins.analog = pinanalog;
            return {pins};
        });

        this.props.editTypeData(this.props.match.params.id, this.state);
    }

    render() {
        if (this.props.type?.success) return <Redirect to="/type"/>;
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <FormContainer className={`pt-0`}>
                        <Input
                            name={"name"}
                            label={"Type name"}
                            value={this.state.name}
                            placeholder={"lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <Input
                            name={"digital"}
                            label={"Pin digital"}
                            value={this.state.digital}
                            placeholder={"0"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <Input
                            name={"analog"}
                            label={"Pin analog"}
                            value={this.state.analog}
                            placeholder={"0"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <hr className={`my-5`}/>
                        <Button
                            className={`bg-green-200 text-green-700`}
                            onClick={this.finishForm.bind(this)}
                        >
                            Submit
                        </Button>
                    </FormContainer>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    type: state.type,
    typeCategory: state.typeCategory,
});

const mapDispatchToProps = {
    editTypeData,
    getTypeData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(TypeEdit)
);
