import React from "react";
import MainLayout from "../../../hoc/main";
import {connect} from "react-redux";
import {FaPlug} from "react-icons/fa";
import {changeBardiStatus, getBardiDatas} from "../../../redux/modules/Bardi/action";
import {Grid} from "../../../components";

class Bardi extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bardi: [],
        };
    }

    componentDidMount() {
        this.props.getBardiDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            bardi: np.bardi.datas.result,
        });
    }

    render() {
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid className={"grid-cols-2 md:grid-cols-4 xl:grid-cols-6"}>
                    {
                        this.state.bardi?.map((bar, i) =>
                            bar.category === "cz" && this.switchController(i, bar)
                        )
                    }
                </Grid>
            </div>
        );
    }

    switchController(i, bar) {
        return (
            <div
                key={i}
                className={`cursor-pointer flex flex-col justify-between shadow p-5 text-gray-400 rounded-lg ${bar?.status[0]?.value ? "hover:bg-gray-100" : "hover:bg-green-100"
                }`}
                onClick={(e) => {
                    e.preventDefault();
                    let command = {
                        "commands": [
                            {
                                "code": bar?.status[0].code,
                                "value": !bar?.status[0].value
                            }
                        ]
                    }
                    this.props.changeBardiStatus(bar?.id, command, (data) => {
                        if (data?.success) {
                            this.setState(prevState => {
                                let bardi = [...prevState.bardi];

                                bardi[i].status[0].value = !bar?.status[0].value

                                return {bardi}
                            })
                        }
                    });
                }}
            >
                <p
                    className={`${bar?.status[0]?.value ? "text-green-500" : "text-gray-400"
                    }`}
                >
                    <FaPlug/>
                </p>
                <div className={"mt-5"}>
                    <p className={"text-gray-400 text-sm md:text-md"}>{bar.name}</p>
                    <p
                        className={`mt-2 font-bold ${bar?.status[0]?.value ? "text-green-500" : "text-gray-400"
                        }`}
                    >
                        {bar?.status[0]?.value ? "ON" : "OFF"}
                    </p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bardi: state.bardi,
});

const mapDispatchToProps = {
    getBardiDatas,
    changeBardiStatus
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(Bardi)
);
