export const Checkbox = ({name, checked, onChange, className, label}) => {
    return (
        <div className={`${className}`}>
            <label className="inline-flex items-center mt-3">
                <input
                    name={name}
                    type="checkbox"
                    className={`form-checkbox h-5 w-5 text-gray-600`}
                    checked={checked}
                    onChange={onChange}
                />
                <span className={`ml-2 text-gray-700`}>{label}</span>
            </label>
        </div>
    );
};
