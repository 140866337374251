import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {getDeletedDeviceDatas, hardDeleteDeviceData, restoreDeviceData,} from "../../../redux/modules/Device/action";

class DeviceTrash extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getDeletedDeviceDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.device.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const deviceColumn = [
            {
                name: "Device",
                path: "name",
                show: true,
            },
            {
                name: "Description",
                path: "description",
                show: true,
            },
            {
                name: "Category",
                path: "type",
                show: true,
                render: (data) => data?.name,
            },
            {
                name: "Status",
                path: "status",
                show: true,
                render: (data) => (data ? "Active" : "Not Active"),
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        path={this.props.location.pathname}
                        title={"Device Category Data"}
                        data={this.props.device.datas}
                        columns={deviceColumn}
                        trashAction={true}
                        deleteAction={(id) => this.props.hardDeleteDeviceData(id)}
                        restoreAction={(id) => this.props.restoreDeviceData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    device: state.device,
});

const mapDispatchToProps = {
    getDeletedDeviceDatas,
    hardDeleteDeviceData,
    restoreDeviceData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(DeviceTrash)
);
