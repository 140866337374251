import React from "react";
import {Link} from "react-router-dom";

export function Button({children, className, onClick}) {
    return (
        <button onClick={onClick} className={`px-3 py-2 rounded ${className}`}>
            {children}
        </button>
    );
}

export const LinkButton = ({
                               link,
                               params,
                               icon,
                               className,
                               onClick,
                               children,
                           }) => {
    return (
        <div className={`py-2 px-3 ${className} rounded`}>
            <Link to={link} params={params} onClick={onClick}>
                {icon ? icon : null}
                {children ? children : null}
            </Link>
        </div>
    );
};

export const ButtonIcon = ({icon, className, onClick}) => {
    return (
        <button className={`p-2 ${className} rounded`} onClick={onClick}>
            {icon}
        </button>
    );
};

// export function ButtonWithIcon({ icon, className, onClick, children }) {
//   return (
//     <button className={`btn ${className}`} onClick={onClick}>
//       <FontAwesomeIcon icon={icon} />
//       <span className={`ml-2 `}>{children}</span>
//     </button>
//   );
// }
