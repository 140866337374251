import {receptionistDeviceTypes} from "./type";
import {BaseAction} from "../../actions/base";

const types = receptionistDeviceTypes;

const path = `iot/receptionist-device`;

const base = new BaseAction(types);

export const getReceptionistDeviceDatas = () => (dispatch) => {
    return base.getDatas(path, dispatch);
};

export const getDeletedReceptionistDeviceDatas = () => (dispatch) => {
    return base.getDatas(`${path}/deleted`, dispatch);
};

export const getReceptionistDeviceData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}`, dispatch);
};

export const getDeletedReceptionistDeviceData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}/deleted`, dispatch);
};

export const addReceptionistDeviceData = (newData) => (dispatch) => {
    return base.addData(path, dispatch, newData);
};

export const editReceptionistDeviceData = (id, newData) => (dispatch) => {
    return base.editData(`${path}/${id}`, dispatch, newData);
};

export const deleteReceptionistDeviceData = (id) => (dispatch) => {
    return base.deleteData(`${path}/${id}`, dispatch, id);
};

export const restoreReceptionistDeviceData = (id) => (dispatch) => {
    return base.restoreData(`${path}/${id}/restore`, dispatch, id);
};

export const hardDeleteReceptionistDeviceData = (id) => (dispatch) => {
    return base.hardDeleteData(`${path}/${id}/confirmdelete`, dispatch, id);
};
