import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {
    deleteReceptionistDeviceData,
    getReceptionistDeviceDatas,
} from "../../../redux/modules/ReceptionistDevice/action";

class ReceptionistDevice extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getReceptionistDeviceDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.receptionistDevice.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const receptionistDeviceColumn = [
            {
                name: "Name",
                path: "name",
                show: true,
            },
            {
                name: "UNUM",
                path: "UNUM",
                show: true,
            },
            {
                name: "Charger Name",
                path: "charger",
                show: true,
                render: (data) => data.result.name,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        addData={true}
                        trashBin={true}
                        path={this.props.location.pathname}
                        title={"Receptionist Device Data"}
                        data={this.props.receptionistDevice.datas}
                        columns={receptionistDeviceColumn}
                        action={["update", "delete"]}
                        trash={true}
                        deleteAction={(id) => this.props.deleteReceptionistDeviceData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    receptionistDevice: state.receptionistDevice,
});

const mapDispatchToProps = {
    getReceptionistDeviceDatas,
    deleteReceptionistDeviceData,
};

export default MainLayout(connect(mapStateToProps, mapDispatchToProps)(ReceptionistDevice));
