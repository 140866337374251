import React from "react";
import {Typography} from "../../Typography";

export const SidebarTitle = ({title}) => {
    return (
        <div
            className={
                "bg-green-800 text-gray-100 my-3 rounded-md p-4 text-xl text-center font-bold xl:block"
            }
        >
            <Typography text={title}/>
        </div>
    );
};
