import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {
    getDeletedGuestAccessDatas,
    hardDeleteGuestAccessData,
    restoreGuestAccessData,
} from "../../../redux/modules/GuestAccess/action";

class GuestAccessTrash extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getDeletedGuestAccessDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.guestAccess.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const guestAccessColumn = [
            {
                name: "Name",
                path: "name",
                show: true,
            },
            {
                name: "Number",
                path: "accessNumber",
                show: true,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        path={this.props.location.pathname}
                        title={"Guest Access Data"}
                        data={this.props.guestAccess.datas}
                        columns={guestAccessColumn}
                        trashAction={true}
                        deleteAction={(id) => this.props.hardDeleteGuestAccessData(id)}
                        restoreAction={(id) => this.props.restoreGuestAccessData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    guestAccess: state.guestAccess,
});

const mapDispatchToProps = {
    getDeletedGuestAccessDatas,
    hardDeleteGuestAccessData,
    restoreGuestAccessData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(GuestAccessTrash)
);
