import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {deleteTimerData, getTimerDatas,} from "../../../redux/modules/Timer/action";
import moment from 'moment';

class Timer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getTimerDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.timer.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const timerColumn = [
            {
                name: "Name",
                path: "name",
                show: true,
            },
            {
                name: "Days",
                path: "days",
                show: true,
                render: (data) => {
                    const days = {
                        1: 'Senin',
                        2: 'Selasa',
                        3: 'Rabu',
                        4: 'Kamis',
                        5: 'Jumat',
                        6: 'Sabtu',
                        7: 'Minggu',
                    }
                    let dayNames = data.sort().map(d => days[d])
                    return dayNames.join(', ')
                }
            },
            {
                name: "Time",
                path: "time",
                show: true,
                render: (data) => moment(data).format("HH:mm"),
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        addData={true}
                        trashBin={true}
                        path={this.props.location.pathname}
                        title={"Timer Data"}
                        data={this.props.timer.datas}
                        columns={timerColumn}
                        action={["update", "delete"]}
                        trash={true}
                        deleteAction={(id) => this.props.deleteTimerData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    timer: state.timer,
});

const mapDispatchToProps = {
    getTimerDatas,
    deleteTimerData,
};

export default MainLayout(connect(mapStateToProps, mapDispatchToProps)(Timer));
