import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {
    getDeletedAccessCategoryDatas,
    hardDeleteAccessCategoryData,
    restoreAccessCategoryData,
} from "../../../redux/modules/AccessCategory/action";

class AccessCategoryTrash extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getDeletedAccessCategoryDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.accessCategory.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const accessCategoryColumn = [
            {
                name: "Access Category",
                path: "name",
                show: true,
            },
            {
                name: "Description",
                path: "description",
                show: true,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        path={this.props.location.pathname}
                        title={"Access Category Data"}
                        data={this.props.accessCategory.datas}
                        columns={accessCategoryColumn}
                        trashAction={true}
                        deleteAction={(id) => this.props.hardDeleteAccessCategoryData(id)}
                        restoreAction={(id) => this.props.restoreAccessCategoryData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    accessCategory: state.accessCategory,
});

const mapDispatchToProps = {
    getDeletedAccessCategoryDatas,
    hardDeleteAccessCategoryData,
    restoreAccessCategoryData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(AccessCategoryTrash)
);
