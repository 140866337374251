import React from "react";
import {LayoutTitle} from "../components";

const MainLayout = (WrappedComponent) => {
    return (props) => {
        return (
            <>
                <LayoutTitle title={props.title} openMenu={props.openMenu}/>

                <WrappedComponent {...props} />
            </>
        );
    };
};

export default MainLayout;
