import {
    FaAddressBook,
    FaAtom, FaBrain,
    FaCheckDouble,
    FaClipboardCheck,
    FaClock,
    FaCogs,
    FaComment,
    FaDoorOpen,
    FaFingerprint,
    FaIdCard,
    FaLaptop,
    FaLink,
    FaListAlt,
    FaSdCard,
    FaSitemap,
    FaStumbleupon,
    FaTablet,
    FaTablets,
    FaThLarge,
    FaToggleOn,
    FaUndo,
    FaUsers,
    FaVideo,
    FaWifi,
} from "react-icons/fa";

export const data = {
    menus: [
        {
            name: "Menu",
            menuItems: [
                {
                    name: "Dashboard",
                    link: "/",
                    icon: <FaThLarge/>,
                },
                {
                    name: "Controller",
                    link: "/controller",
                    icon: <FaToggleOn/>,
                },
                {
                    name: "Bardi",
                    link: "/bardi",
                    icon: <FaWifi/>,
                },
                {
                    name: "Group Control",
                    link: "/group-controller",
                    icon: <FaToggleOn/>,
                },
                {
                    name: "Employees",
                    link: "/employee",
                    icon: <FaUsers/>,
                },
            ],
        },
        {
            name: "Access",
            menuItems: [
                {
                    name: "Category",
                    link: "/category",
                    icon: <FaListAlt/>,
                },
                {
                    name: "Accesses",
                    link: "/access",
                    icon: <FaFingerprint/>,
                },
                {
                    name: "Access Request",
                    link: "/access-request",
                    icon: <FaCheckDouble/>,
                },
            ],
        },
        {
            name: "Device",
            menuItems: [
                {
                    name: "Type",
                    link: "/type",
                    icon: <FaCogs/>,
                },
                {
                    name: "Device",
                    link: "/device",
                    icon: <FaLaptop/>,
                },
                {
                    name: "Action",
                    link: "/action",
                    icon: <FaStumbleupon/>,
                },
                {
                    name: "Action Group",
                    link: "/action-group",
                    icon: <FaSitemap/>,
                },
                {
                    name: "Device Request",
                    link: "/device-request",
                    icon: <FaClipboardCheck/>,
                },
                {
                    name: "Card Reader",
                    link: "/card-reader",
                    icon: <FaSdCard/>,
                },
                {
                    name: "Timer",
                    link: "/timer",
                    icon: <FaClock/>,
                },
            ],
        },
        // {
        //   name: "Catalog",
        //   menuItems: [
        //     {
        //       name: "AC Category",
        //       link: "/ac-category",
        //       icon: <FaInbox />,
        //     },
        //     {
        //       name: "AC Brand",
        //       link: "/ac-brand",
        //       icon: <FaFolderOpen />,
        //     },
        //     {
        //       name: "AC Freon Type",
        //       link: "/ac-freon-type",
        //       icon: <FaDatabase />,
        //     },
        //     {
        //       name: "List AC",
        //       link: "/ac",
        //       icon: <FaSnowflake />,
        //     },
        //   ],
        // },
        {
            name: "Chit Chat",
            menuItems: [
                {
                    name: "Entities",
                    link: "/entity",
                    icon: <FaSitemap/>,
                },
                {
                    name: "Intents",
                    link: "/intent",
                    icon: <FaComment/>,
                },
                {
                    name: "Learn",
                    link: "/learn",
                    icon: <FaBrain/>,
                },
            ],
        },
        {
            name: "Showroom",
            menuItems: [
                {
                    name: "Card",
                    link: "/guest-card",
                    icon: <FaIdCard/>,
                },
                {
                    name: "Guest",
                    link: "/guest",
                    icon: <FaAddressBook/>,
                },
                {
                    name: "Guest Access",
                    link: "/guest-access",
                    icon: <FaDoorOpen/>,
                },
                {
                    name: "Connector",
                    link: "/connector",
                    icon: <FaLink/>,
                },
                {
                    name: "Video",
                    link: "/video",
                    icon: <FaVideo/>,
                },
                {
                    name: "Presentation",
                    link: "/presentation",
                    icon: <FaAtom/>,
                },
                {
                    name: "Receptionist Device",
                    link: "/receptionist-device",
                    icon: <FaTablet/>,
                },
                {
                    name: "Showroom Device",
                    link: "/showroom-device",
                    icon: <FaTablets/>,
                },
            ],
        },
        {
            name: "Others",
            menuItems: [
                {
                    name: "Activity Log",
                    link: "/log",
                    icon: <FaUndo/>,
                },
            ],
        },
    ],
};
