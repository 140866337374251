import React from "react";

import {Sidebar} from "../Sidebar";

export const Layout = ({
                           children,
                           menuOpened,
                           closeMenu,
                           profileName,
                           profilePicture,
                           pathName,
                           logoutAction
                       }) => {
    return (
        <div className={"h-screen flex flex-col xl:flex-row"}>
            <Sidebar
                menuOpened={menuOpened}
                closeMenu={closeMenu}
                profileName={profileName}
                profilePicture={profilePicture}
                pathName={pathName}
                logoutAction={logoutAction}
            />

            <main className={" flex-1 xl:ml-60 bg-gray-50 "}>
                {/* Head section start */}
                {children}
            </main>
        </div>
    );
};
