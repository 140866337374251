import {accessCategoryTypes} from "./type";
import {BaseAction} from "../../actions/base";

const types = accessCategoryTypes;

const path = `iot/category`;

const base = new BaseAction(types);

export const getAccessCategoryDatas = () => (dispatch) => {
    return base.getDatas(path, dispatch);
};

export const getDeletedAccessCategoryDatas = () => (dispatch) => {
    return base.getDatas(`${path}/deleted`, dispatch);
};

export const getAccessCategoryData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}`, dispatch);
};

export const getDeletedAccessCategoryData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}/deleted`, dispatch);
};

export const addAccessCategoryData = (newData) => (dispatch) => {
    return base.addData(path, dispatch, newData);
};

export const editAccessCategoryData = (id, newData) => (dispatch) => {
    return base.editData(`${path}/${id}`, dispatch, newData);
};

export const deleteAccessCategoryData = (id) => (dispatch) => {
    return base.deleteData(`${path}/${id}`, dispatch, id);
};

export const restoreAccessCategoryData = (id) => (dispatch) => {
    return base.restoreData(`${path}/${id}/restore`, dispatch, id);
};

export const hardDeleteAccessCategoryData = (id) => (dispatch) => {
    return base.hardDeleteData(`${path}/${id}/confirmdelete`, dispatch, id);
};
