import React from "react";
import {Typography} from "../../../Typography";
import {Link} from "react-router-dom";

export const SidebarSubmenuItem = ({item, icon, link}) => {
    return (
        <Link
            to={link}
            className="group text-sm transition hover:transition ease-in-out duration-100 flex flex-row items-center mt-5 text-gray-500 hover:bg-green-200 hover:text-gray-800 hover:p-3 rounded-md hover:font-bold"
        >
            <span className={"group-hover:text-green-800"}>{icon}</span>
            <span className={"ml-4"}>
        <Typography text={item}/>
      </span>
        </Link>
    );
};

export const SidebarSubmenuItemActive = ({item, icon, link}) => {
    return (
        <Link
            to={link}
            className="group bg-green-200 text-sm transition hover:transition ease-in-out duration-100 flex flex-row items-center mt-5 text-gray-800 p-3 rounded-md font-bold"
        >
            <span className={"text-green-800"}>{icon}</span>
            <span className={"ml-4"}>
        <Typography text={item}/>
      </span>
        </Link>
    );
};
