import React from "react";
import {SidebarSubmenu} from "./Submenu";

export const SidebarMenu = ({data, activeMenu}) => {
    return (
        <div className="px-5 mb-40">
            {data.menus.map((d, i) => {
                return (
                    <SidebarSubmenu
                        activeMenu={activeMenu}
                        subMenuName={d.name}
                        subMenuItems={d.menuItems}
                        key={i}
                    />
                );
            })}
        </div>
    );
};
