import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {getDeletedActionDatas, hardDeleteActionData, restoreActionData,} from "../../../redux/modules/Action/action";

class ActionTrash extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getDeletedActionDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.action.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const actionColumn = [
            {
                name: "Name",
                path: "name",
                show: true,
            },
            {
                name: "Description",
                path: "description",
                show: true,
            },
            {
                name: "Device",
                path: "device",
                show: true,
                render: (data) => data?.name,
            },
            {
                name: "Action",
                path: "action",
                show: true,
            },
            {
                name: "Status",
                path: "status",
                show: true,
                render: (data) => (data ? "Active" : "Not Active"),
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        path={this.props.location.pathname}
                        title={"Action Category Data"}
                        data={this.props.action.datas}
                        columns={actionColumn}
                        trashAction={true}
                        deleteAction={(id) => this.props.hardDeleteActionData(id)}
                        restoreAction={(id) => this.props.restoreActionData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    action: state.action,
});

const mapDispatchToProps = {
    getDeletedActionDatas,
    hardDeleteActionData,
    restoreActionData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(ActionTrash)
);
