import React from "react";
import MainLayout from "../../../hoc/main";
import {Checkbox, Dropdown, FormContainer, Grid, Input, TextArea,} from "../../../components";
import {connect} from "react-redux";
import {editRequestData, getRequestData,} from "../../../redux/modules/Request/action";
import {getAccessCategoryDatas} from "../../../redux/modules/AccessCategory/action";
import {Button} from "../../../components/Button";
import {Redirect} from "react-router-dom";

class AccessRequestEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            description: "",
            category: "",
            UNUM: "",
            status: false,
        };
    }

    componentDidMount() {
        this.props.getAccessCategoryDatas();
        this.props.getRequestData(this.props.match.params.id);
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            name: np.request.data.name,
            UNUM: np.request.data.UNUM,
            category: np.request.data.category?.id,
            description: np.request.data.description,
            status: np.request.data.status,
        });
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });
    };

    onChangeCheckBox = (e) => {
        const {checked, name} = e.target;
        this.setState({
            [name]: checked,
        });
    };

    finishForm(e) {
        e.preventDefault();
        this.props.editRequestData(this.props.match.params.id, this.state);
    }

    render() {
        if (this.props.request?.success) return <Redirect to="/request"/>;
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <FormContainer className={`pt-0`}>
                        <Input
                            name={"UNUM"}
                            label={"Request UNUM"}
                            value={this.state.UNUM}
                            placeholder={"XX:XX:XX:XX:XX"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <Input
                            name={"name"}
                            label={"Request name"}
                            value={this.state.name}
                            placeholder={"Lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <TextArea
                            name={"description"}
                            label={"Description"}
                            value={this.state.description}
                            placeholder={"Lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                            rows={5}
                        />
                        <Dropdown
                            label={"Category"}
                            data={this.props.requestCategory.datas}
                            expectedValue={"id"}
                            display={"name"}
                            name={"category"}
                            value={this.state.category}
                            onChange={this.onChangeValue}
                            placeholder="Choose one category"
                        />
                        <Checkbox
                            label={"Device Status"}
                            checked={this.state.status}
                            name={"status"}
                            onChange={this.onChangeCheckBox}
                            className={"mt-5"}
                        />
                        <hr className={`my-5`}/>
                        <Button
                            className={`bg-green-200 text-green-700`}
                            onClick={this.finishForm.bind(this)}
                        >
                            Submit
                        </Button>
                    </FormContainer>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    request: state.request,
    requestCategory: state.requestCategory,
});

const mapDispatchToProps = {
    editRequestData,
    getAccessCategoryDatas,
    getRequestData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(AccessRequestEdit)
);
