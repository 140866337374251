import React from "react";
import MainLayout from "../../../hoc/main";
import {Dropdown, FormContainer, Grid, Input} from "../../../components";
import {connect} from "react-redux";
import {addShowroomDeviceData} from "../../../redux/modules/ShowroomDevice/action";
import {Button} from "../../../components/Button";
import {Redirect} from "react-router-dom";
import {getBardiDatas} from "../../../redux/modules/Bardi/action";
import {getReceptionistDeviceDatas} from "../../../redux/modules/ReceptionistDevice/action";
import {getCardReaderDatas} from "../../../redux/modules/CardReader/action";

class ShowroomDeviceAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            chargerId: "",
            UNUM: "",
            scanner: "",
            receptionist: "",
        };
    }

    componentDidMount() {
        this.props.getBardiDatas();
        this.props.getReceptionistDeviceDatas();
        this.props.getCardReaderDatas();
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });
    };

    onChangeCheckBox = (e) => {
        const {checked, name} = e.target;
        this.setState({
            [name]: checked,
        });
    };

    finishForm(e) {
        e.preventDefault();
        this.props.addShowroomDeviceData(this.state);
    }

    render() {
        if (this.props.showroomDevice?.success) return <Redirect to="/showroom-device"/>;
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <FormContainer className={`pt-0`}>
                        <Input
                            name={"name"}
                            label={"ShowroomDevice name"}
                            value={this.state.name}
                            placeholder={"Lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <Input
                            name={"UNUM"}
                            label={"Device UNUM"}
                            value={this.state.UNUM}
                            placeholder={"XX:XX:XX:XX:XX"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <Dropdown
                            label={"Charger"}
                            data={this.props.bardi.datas.result}
                            expectedValue={"id"}
                            display={"name"}
                            name={"chargerId"}
                            value={this.state.chargerId}
                            onChange={this.onChangeValue}
                            placeholder="Choose one category"
                        />
                        <Dropdown
                            label={"Receptionist"}
                            data={this.props.receptionistDevice.datas}
                            expectedValue={"id"}
                            display={"name"}
                            name={"receptionist"}
                            value={this.state.receptionist}
                            onChange={this.onChangeValue}
                            placeholder="Choose one category"
                        />
                        <Dropdown
                            label={"Scanner / Card Reader"}
                            data={this.props.cardReader.datas}
                            expectedValue={"id"}
                            display={"name"}
                            name={"scanner"}
                            value={this.state.scanner}
                            onChange={this.onChangeValue}
                            placeholder="Choose one category"
                        />

                        <hr className={`my-5`}/>
                        <Button
                            className={`bg-green-200 text-green-700`}
                            onClick={this.finishForm.bind(this)}
                        >
                            Submit
                        </Button>
                    </FormContainer>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    showroomDevice: state.showroomDevice,
    bardi: state.bardi,
    cardReader: state.cardReader,
    receptionistDevice: state.receptionistDevice,
});

const mapDispatchToProps = {
    addShowroomDeviceData,
    getBardiDatas,
    getReceptionistDeviceDatas,
    getCardReaderDatas
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(ShowroomDeviceAdd)
);
