import React from "react";
import MainLayout from "../../../hoc/main";
import {Dropdown, FormContainer, Grid} from "../../../components";
import {connect} from "react-redux";
import {addGuestAccessData, getGuestAccessData,} from "../../../redux/modules/GuestAccess/action";

import {getGuestDatas} from "../../../redux/modules/Guest/action";
import {getGuestCardDatas} from "../../../redux/modules/GuestCard/action";
import {Button} from "../../../components/Button";
import {Redirect} from "react-router-dom";

class GuestAccessAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            guest: "",
            card: "",
        };
    }

    componentDidMount() {
        this.props.getGuestDatas();
        this.props.getGuestCardDatas();
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });
    };

    finishForm(e) {
        e.preventDefault();
        this.props.addGuestAccessData(this.state);
    }

    render() {
        if (this.props.guestAccess?.success) return <Redirect to="/guest-access"/>;
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <FormContainer className={`pt-0`}>
                        <Dropdown
                            label={"Card"}
                            data={this.props.guestCard.datas}
                            expectedValue={"id"}
                            display={"name"}
                            name={"card"}
                            value={this.state.card}
                            onChange={this.onChangeValue}
                            placeholder="Choose one card"
                        />
                        <div className="mt-5">
                            <Dropdown
                                label={"Guest"}
                                data={this.props.guest.datas}
                                expectedValue={"id"}
                                display={"name"}
                                name={"guest"}
                                value={this.state.guest}
                                onChange={this.onChangeValue}
                                placeholder="Choose one guest"
                            />
                        </div>
                        <hr className={`my-5`}/>
                        <Button
                            className={`bg-green-200 text-green-700`}
                            onClick={this.finishForm.bind(this)}
                        >
                            Submit
                        </Button>
                    </FormContainer>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    guestAccess: state.guestAccess,
    guestCard: state.guestCard,
    guest: state.guest,
});

const mapDispatchToProps = {
    addGuestAccessData,
    getGuestAccessData,
    getGuestCardDatas,
    getGuestDatas,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(GuestAccessAdd)
);
