import {combineReducers} from "redux";
import acReducer from "../modules/Ac/reducer";
import acBrandReducer from "../modules/AcBrand/reducer";
import acCategoryReducer from "../modules/AcCategory/reducer";
import accessReducer from "../modules/Access/reducer";
import accessCategoryReducer from "../modules/AccessCategory/reducer";
import acFreonTypeReducer from "../modules/AcFreonType/reducer";
import actionReducer from "../modules/Action/reducer";
import actionGroupReducer from "../modules/ActionGroup/reducer";
import cardReaderReducer from "../modules/CardReader/reducer";
import connectorReducer from "../modules/Connector/reducer";
import deviceReducer from "../modules/Device/reducer";
import employeeReducer from "../modules/Employee/reducer";
import entityReducer from "../modules/Entity/reducer";
import guestReducer from "../modules/Guest/reducer";
import guestAccessReducer from "../modules/GuestAccess/reducer";
import guestCardReducer from "../modules/GuestCard/reducer";
import intentReducer from "../modules/Intent/reducer";
import logAccessReducer from "../modules/LogAccess/reducer";
import presentationReducer from "../modules/Presentation/reducer";
import requestReducer from "../modules/Request/reducer";
import typeReducer from "../modules/Type/reducer";
import videoReducer from "../modules/Video/reducer";
import timerReducer from "../modules/Timer/reducer";
import bardiReducer from "../modules/Bardi/reducer";
import authReducer from "../modules/Auth/reducer";
import receptionistDeviceReducer from "../modules/ReceptionistDevice/reducer";
import showroomDeviceReducer from "../modules/ShowroomDevice/reducer";
import learnReducer from "../modules/Learn/reducer";

export default combineReducers({
    access: accessReducer,
    logAccess: logAccessReducer,
    employee: employeeReducer,
    action: actionReducer,
    request: requestReducer,
    accessCategory: accessCategoryReducer,
    type: typeReducer,
    device: deviceReducer,
    acCategory: acCategoryReducer,
    acFreonType: acFreonTypeReducer,
    acBrand: acBrandReducer,
    ac: acReducer,
    guest: guestReducer,
    guestCard: guestCardReducer,
    guestAccess: guestAccessReducer,
    video: videoReducer,
    presentation: presentationReducer,
    connector: connectorReducer,
    cardReader: cardReaderReducer,
    actionGroup: actionGroupReducer,
    entity: entityReducer,
    intent: intentReducer,
    timer: timerReducer,
    bardi: bardiReducer,
    auth: authReducer,
    receptionistDevice: receptionistDeviceReducer,
    showroomDevice: showroomDeviceReducer,
    learn: learnReducer,
});
