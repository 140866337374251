import React from "react";
import {FaEllipsisH, FaTrashAlt, FaTrashRestore} from "react-icons/fa";
import {Input} from "../";
import {Dropdown, DropdownChecklist} from "../Forms/Dropdown";
import {Pagination} from "./pagination";
import {ButtonIcon, LinkButton} from "../Button";

/* This is how to count

  This is page 2 
  Last Index
  2 * 10 = 20 
  First Index
  20 - 10 = 10

  so we will get index 10 - 20 for perPage
  */
export class TableData extends React.Component {
    s;

    constructor(props) {
        super(props);

        this.state = {
            search: "",
            perPage: 0,
            currentPage: 1,
            columns: [],
            data: [],
        };
    }

    componentDidMount() {
        const newCol = [
            {
                name: "Unique ID",
                path: this.props.customUpdateId ? this.props.customUpdateId : "id",
                show: true
            },
            ...this.props.columns,
            {
                name: "Created At",
                path: "createdAt",
                show: false,
            },
            {
                name: "Updated At",
                path: "updatedAt",
                show: false,
            },
        ];

        this.setState({
            columns: newCol,
            perPage: this.props.perPage || 10,
            data: this.props.data,
        });
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            data: np.data,
        });
    }

    changePerPage = (e) => {
        e.preventDefault();
        this.setState({
            perPage: e.target.value,
            currentPage: 1,
        });
    };

    changeShowColumn = (index, show) => {
        this.setState((prevState) => {
            let columns = [...prevState.columns];

            columns[index].show = show;

            return {columns};
        });
    };

    filterIt(arr, searchKey) {
        return arr.filter((obj) => {
            return this.filterObject(obj, searchKey);
        });
    }

    filterObject(obj, searchKey) {
        if (!obj) {
            return;
        }
        return Object.keys(obj).some((key) => {
            if (typeof obj[key] !== "object") {
                return String(obj[key]).toLowerCase().includes(searchKey.toLowerCase());
            }
            return this.filterObject(obj[key], searchKey);
        });
    }

    renderHeader = () => {
        const colData = this.state.columns.map((col) =>
            col?.show ? col?.name : null
        );
        const headTable = [...colData];

        return (
            <tr>
                {headTable.map((head, index) =>
                    head === undefined || head === null ? null : (
                        <th key={index}>{head}</th>
                    )
                )}
                {this.props.action || this.props.trashAction ? <th>Action</th> : null}
            </tr>
        );
    };

    renderDataBody = (indexOfFirstPost, indexOfLastPost) => {
        let searchData = this.filterIt(this.state.data, this.state.search);

        if (searchData.length > 0) {
            let currentPosts = searchData.slice(indexOfFirstPost, indexOfLastPost);

            return currentPosts.map((val, index) => {
                return (
                    <tr key={index}>
                        {this.state.columns.map((col, ind) =>
                            col.show ? (
                                !col.render ? (
                                    <td key={ind}>{val[col.path]}</td>
                                ) : (
                                    <td key={ind}>{col.render(val[col.path])}</td>
                                )
                            ) : null
                        )}
                        {this.props.action ? (
                            <td>
                                <div className={"flex justify-center items-center"}>
                                    {this.props.action.includes("update") ? this.props.customUpdate ? this.props.customUpdate(val[this.props.customUpdateId]) :
                                        <LinkButton
                                            className={`bg-yellow-200 text-yellow-700`}
                                            icon={<FaEllipsisH/>}
                                            link={`${this.props.path}/${val.id}`}
                                        /> : null}
                                    {this.props.action.includes("delete") ? (
                                        <ButtonIcon
                                            className={"ml-2 bg-red-200 text-red-700"}
                                            icon={<FaTrashAlt/>}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                console.log(val[this.props.customDeleteId] ?? val.id)
                                                this.props.deleteAction(val[this.props.customDeleteId] ?? val.id);
                                            }}
                                        />
                                    ) : null}
                                </div>
                            </td>
                        ) : null}

                        {this.props.trashAction && val?.deleted ? (
                            <td>
                                <div className={"flex justify-center items-center"}>
                                    <ButtonIcon
                                        className={"ml-2 bg-blue-200 text-blue-700"}
                                        icon={<FaTrashRestore/>}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.restoreAction(val.id);
                                        }}
                                    />
                                    <ButtonIcon
                                        className={"ml-2 bg-red-200 text-red-700"}
                                        icon={<FaTrashAlt/>}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.deleteAction(val.id);
                                        }}
                                    />
                                </div>
                            </td>
                        ) : null}
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan={this.state.columns.length + 3}>Data tidak ditemukan</td>
                </tr>
            );
        }
    };

    render() {
        const indexOfLastPost = this.state.currentPage * this.state.perPage; // 2 * 10 = 20
        const indexOfFirstPost = indexOfLastPost - this.state.perPage; // 20 - 10 = 10

        return (
            <div className={"bg-white p-5 rounded-lg shadow-md "}>
                <div className="block md:flex justify-between items-center">
                    <h3 className={"font-semibold text-gray-600 text-lg "}>
                        {this.props.title}
                    </h3>
                    <div className="flex mt-5 md:mt-0 justify-between md:justify-end">
                        {this.props.addData ? (
                            <LinkButton
                                link={`${this.props.path}/add`}
                                className={"bg-gray-700 text-gray-200 text-xs"}
                            >
                                Add New Data
                            </LinkButton>
                        ) : null}
                        {this.props.trashBin ? (
                            <LinkButton
                                link={`${this.props.path}/trash`}
                                className={"bg-red-200 text-red-700 text-xs ml-3"}
                            >
                                Trash Bin
                            </LinkButton>
                        ) : null}
                    </div>
                </div>
                <div className="flex justify-between flex-col md:flex-row mt-5">
                    <div className="flex flex-row justify-between md:justify-start">
                        <Dropdown
                            data={[5, 10, 20, 50, 100, 500, 1000]}
                            name={"perPage"}
                            value={this.state.perPage}
                            onChange={this.changePerPage.bind(this)}
                            className={`text-sm text-gray-400`}
                        />
                        <DropdownChecklist
                            data={this.state.columns}
                            className={`ml-3 text-sm text-gray-400`}
                            onClick={this.changeShowColumn}
                        />
                    </div>
                    <Input
                        className={"mt-5 md:mt-0 w-full md:w-auto"}
                        name={"search"}
                        value={this.state.search}
                        onChange={(e) => this.setState({search: e.target.value})}
                        placeholder="Find something...."
                    />
                </div>
                <div className="overflow-x-auto">
                    <table className="mt-5 w-full table-auto text-xs md:text-md">
                        <thead>{this.renderHeader()}</thead>
                        <tbody>
                        {this.renderDataBody(indexOfFirstPost, indexOfLastPost)}
                        </tbody>
                    </table>
                </div>
                <div
                    className={`mt-5 flex justify-between items-center flex-col md:flex-row`}
                >
                    <p className={`text-sm font-light mb-3 md:mb-0`}>
                        Showing{" "}
                        <span className={`font-medium`}>{indexOfFirstPost + 1}</span> to{" "}
                        <span className={`font-medium`}>
              {indexOfLastPost < this.state.data.length
                  ? indexOfLastPost
                  : this.state.data.length}
            </span>{" "}
                        of <span className={`font-medium`}>{this.state.data.length}</span>{" "}
                        entries
                    </p>
                    <Pagination
                        currentPage={this.state.currentPage}
                        perPage={this.state.perPage}
                        totalData={this.state.data.length}
                        onClickPage={(currentPage) => this.setState({currentPage})}
                    />
                </div>
            </div>
        );
    }
}
