import {accessTypes} from "./type";
import {BaseAction} from "../../actions/base";

const types = accessTypes;

const path = `iot/access`;

const base = new BaseAction(types);

export const getAccessDatas = () => (dispatch) => {
    return base.getDatas(path, dispatch);
};

export const getDeletedAccessDatas = () => (dispatch) => {
    return base.getDatas(`${path}/deleted`, dispatch);
};

export const getAccessData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}`, dispatch);
};

export const getDeletedAccessData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}/deleted`, dispatch);
};

export const addAccessData = (newData) => (dispatch) => {
    return base.addData(path, dispatch, newData);
};

export const editAccessData = (id, newData) => (dispatch) => {
    return base.editData(`${path}/${id}`, dispatch, newData);
};

export const deleteAccessData = (id) => (dispatch) => {
    return base.deleteData(`${path}/${id}`, dispatch, id);
};

export const restoreAccessData = (id) => (dispatch) => {
    return base.restoreData(`${path}/${id}/restore`, dispatch, id);
};

export const hardDeleteAccessData = (id) => (dispatch) => {
    return base.hardDeleteData(`${path}/${id}/confirmdelete`, dispatch, id);
};
