import React from "react";
import MainLayout from "../../../hoc/main";
import {Dropdown, FormContainer, Grid} from "../../../components";
import {connect} from "react-redux";
import {editPresentationData, getPresentationData,} from "../../../redux/modules/Presentation/action";
import {getActionDatas} from "../../../redux/modules/Action/action";
import {getVideoDatas} from "../../../redux/modules/Video/action";
import {Button} from "../../../components/Button";
import {Redirect} from "react-router-dom";
import {FaTrashAlt} from "react-icons/fa";
import {getBardiDatas} from "../../../redux/modules/Bardi/action";

class PresentationEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            video: "",
            sequence: [{time: "00:00", event: [{deviceId: "", state: []}]}],
        };
    }

    componentDidMount() {
        this.props.getActionDatas();
        this.props.getVideoDatas();
        this.props.getBardiDatas();
        this.props.getPresentationData(this.props.match.params.id);
    }

    UNSAFE_componentWillReceiveProps(np) {
        // console.log(np.presentation);
        let sequence = np.presentation?.data?.sequence;

        console.log(sequence);

        this.setState({
            video: np.presentation?.data?.video?.id,
            sequence: np.presentation?.data?.sequence,
        });
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });
    };

    finishForm(e) {
        e.preventDefault();
        this.props.editPresentationData(this.props.match.params.id, this.state);
    }

    addSequence(e) {
        e.preventDefault();

        this.setState((prevState) => {
            let sequence = [...prevState.sequence];

            sequence.push({time: "00:00", event: [{deviceId: "", state: [{code: "", value: null}]}]});

            return {sequence};
        });
    }

    letsFindAction(findWhat) {
        let data = this.props.action?.datas?.find((x) => x.id === findWhat);

        return data;
    }

    letsFindBardi(findWhat) {
        let data = this.props.bardi?.datas?.result?.find((x) => x.id === findWhat);

        return data;
    }

    render() {
        if (this.props.presentation?.success)
            return <Redirect to="/presentation"/>;
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <FormContainer className={`pt-0`}>
                        <h2 className={"mt-5 font-medium"}>Video</h2>
                        <Dropdown
                            className={"bg-green-200 mt-3 focus:bg-green-200"}
                            placeholder={"Choose video"}
                            data={this.props.video?.datas}
                            display={"name"}
                            value={this.state.video}
                            expectedValue={"id"}
                            name={"video"}
                            onChange={this.onChangeValue.bind(this)}
                        />
                        <h2 className={"mt-5 font-medium"}>Sequence Presentation</h2>
                        <div className="m-5 p-5 pt-0">
                            {this.state.sequence?.length > 0 ? (
                                this.state.sequence?.map((seq, i) => {
                                    let timeValue = seq.time.split(":");
                                    return (
                                        <div key={i} className={"mt-5 bg-gray-100 p-5 rounded-md"}>
                                            <div className={"font-medium flex items-center mr-5"}>
                                                Video Time :{" "}
                                                <input
                                                    className={
                                                        "mx-2 py-2 px-3 rounded-md w-12 text-center"
                                                    }
                                                    value={timeValue[0]}
                                                    onBlur={(e) =>
                                                        this.setState((prevState) => {
                                                            let sequence = [...prevState.sequence];

                                                            let time = sequence[i].time.split(":");
                                                            let inputTime;

                                                            if (e.target.value === "") {
                                                                inputTime = "00";
                                                            } else {
                                                                inputTime = e.target.value;
                                                            }

                                                            time[0] = inputTime;

                                                            sequence[i].time = `${time[0]}:${time[1]}`;

                                                            return {sequence};
                                                        })
                                                    }
                                                    onChange={(e) =>
                                                        this.setState((prevState) => {
                                                            let sequence = [...prevState.sequence];

                                                            let time = sequence[i].time.split(":");
                                                            let inputTime;
                                                            if (e.target.value > 23) {
                                                                inputTime = "23";
                                                            } else {
                                                                inputTime = e.target.value;
                                                            }
                                                            time[0] = inputTime;

                                                            sequence[i].time = `${time[0]}:${time[1]}`;

                                                            return {sequence};
                                                        })
                                                    }
                                                />
                                                <input
                                                    className={
                                                        "mx-2 py-2 px-3 rounded-md w-12 text-center"
                                                    }
                                                    value={timeValue[1]}
                                                    onBlur={(e) =>
                                                        this.setState((prevState) => {
                                                            let sequence = [...prevState.sequence];

                                                            let time = sequence[i].time.split(":");
                                                            let inputTime;

                                                            if (e.target.value === "") {
                                                                inputTime = "00";
                                                            } else {
                                                                inputTime = e.target.value;
                                                            }

                                                            time[1] = inputTime;

                                                            sequence[i].time = `${time[0]}:${time[1]}`;

                                                            return {sequence};
                                                        })
                                                    }
                                                    onChange={(e) =>
                                                        this.setState((prevState) => {
                                                            let sequence = [...prevState.sequence];

                                                            let time = sequence[i].time.split(":");
                                                            let inputTime;
                                                            if (e.target.value > 59) {
                                                                inputTime = "59";
                                                            } else {
                                                                inputTime = e.target.value;
                                                            }
                                                            time[1] = inputTime;

                                                            sequence[i].time = `${time[0]}:${time[1]}`;

                                                            return {sequence};
                                                        })
                                                    }
                                                />
                                                <Button
                                                    className={`bg-red-200 text-red-700 ml-5`}
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        this.setState((prevState) => {
                                                            let sequence = [...prevState.sequence];
                                                            sequence.splice(i, 1);

                                                            return {sequence};
                                                        });
                                                    }}
                                                >
                                                    <FaTrashAlt/>
                                                </Button>
                                            </div>
                                            <hr className={"my-3 border-gray-500"}/>

                                            {seq?.event?.map((ev, iv) => (
                                                <div className={"ml-5 mt-5"} key={iv}>
                                                    <div className={"flex flex-row items-center"}>
                                                        Event - {iv + 1}
                                                        <Button
                                                            className={`bg-yellow-200 text-yellow-700 ml-5 text-xs`}
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                this.setState((prevState) => {
                                                                    let sequence = [...prevState.sequence];
                                                                    sequence[i]?.event?.splice(iv, 1);

                                                                    return {sequence};
                                                                });
                                                            }}
                                                        >
                                                            X
                                                        </Button>
                                                    </div>
                                                    <div className={"flex flex-row"}>
                                                        <Dropdown
                                                            className={"bg-green-200 mt-3 focus:bg-green-200"}
                                                            placeholder={"Choose on action"}
                                                            data={this.props.bardi.datas.result}
                                                            display={"name"}
                                                            value={ev?.deviceId}
                                                            expectedValue={"id"}
                                                            onChange={(e) =>
                                                                this.setState((prevState) => {
                                                                    let sequence = [...prevState.sequence];

                                                                    sequence[i].event[iv].deviceId = e.target.value;

                                                                    return {sequence};
                                                                })
                                                            }
                                                        />
                                                        {ev?.deviceId !== "" &&
                                                            this.letsFindBardi(ev?.deviceId)?.category ===
                                                            "cz" && (
                                                                <Dropdown
                                                                    className={
                                                                        "bg-gray-200 ml-3 mt-3 focus:bg-gray-200"
                                                                    }
                                                                    placeholder={"Choose on action"}
                                                                    data={[
                                                                        {display: "ON", value: "true"},
                                                                        {display: "OFF", value: "false"},
                                                                    ]}
                                                                    display={"display"}
                                                                    expectedValue={"value"}
                                                                    value={
                                                                        this.state.sequence[i]?.event[iv]?.state[0]?.value
                                                                    }
                                                                    onChange={(e) =>
                                                                        this.setState((prevState) => {
                                                                            let sequence = [...prevState.sequence];

                                                                            sequence[i].event[iv].state[0].code =
                                                                                "switch_1";
                                                                            sequence[i].event[iv].state[0].value =
                                                                                e.target.value;

                                                                            return {sequence};
                                                                        })
                                                                    }
                                                                />
                                                            )}
                                                    </div>
                                                </div>
                                            ))}

                                            <Button
                                                className={`bg-pink-200 text-pink-700 mt-5`}
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    this.setState((prevState) => {
                                                        let sequence = [...prevState.sequence];
                                                        let event = [...sequence[i].event];

                                                        event.push({deviceId: "", state: [{code: "", value: null}]});

                                                        sequence[i].event = event;

                                                        return {sequence};
                                                    });
                                                }}
                                            >
                                                Add new action
                                            </Button>
                                        </div>
                                    );
                                })
                            ) : (
                                <h3 className={"font-medium"}>Tidak ada data sequence</h3>
                            )}
                        </div>

                        <Button
                            className={`bg-purple-200 text-purple-700`}
                            onClick={this.addSequence.bind(this)}
                        >
                            Add new event sequence
                        </Button>
                        <hr className={`my-5`}/>
                        <Button
                            className={`bg-green-200 text-green-700`}
                            onClick={this.finishForm.bind(this)}
                        >
                            Submit
                        </Button>
                    </FormContainer>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    presentation: state.presentation,
    action: state.action,
    video: state.video,
    bardi: state.bardi,
});

const mapDispatchToProps = {
    editPresentationData,
    getPresentationData,
    getActionDatas,
    getVideoDatas,
    getBardiDatas
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(PresentationEdit)
);
