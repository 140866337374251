import React from "react";
import MainLayout from "../../../hoc/main";
import {Datepicker, FormContainer, Grid, Input, TextArea} from "../../../components";
import {connect} from "react-redux";
import {editGuestData, getGuestData,} from "../../../redux/modules/Guest/action";
import {Button} from "../../../components/Button";
import {Redirect} from "react-router-dom";
import moment from "moment";

class GuestEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            identityNumber: "",
            location: "",
            company: "",
            gender: "",
            address: "",
            birthOfDate: "",
        };
    }

    componentDidMount() {
        this.props.getGuestData(this.props.match.params.id);
    }

    UNSAFE_componentWillReceiveProps(np) {
        console.log(moment(np.guest.data.birthOfDate).format("YYYY-MM-DD"));
        this.setState({
            name: np.guest.data.name,
            identityNumber: np.guest.data.identityNumber,
            location: np.guest.data.location,
            company: np.guest.data.company,
            address: np.guest.data.address,
            birthOfDate: moment(np.guest.data.birthOfDate).format("YYYY-MM-DD"),
        });
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });
    };

    finishForm(e) {
        e.preventDefault();
        this.props.editGuestData(this.props.match.params.id, this.state);
    }

    render() {
        if (this.props.guest?.success) return <Redirect to="/guest"/>;
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <FormContainer className={`pt-0`}>
                        <Input
                            name={"name"}
                            label={"Guest name"}
                            value={this.state.name}
                            placeholder={"Lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <Input
                            name={"identityNumber"}
                            label={"Guest identity number"}
                            value={this.state.identityNumber}
                            placeholder={"xxxxxxxxxxxxxxxxxx"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <Input
                            name={"location"}
                            label={"Location"}
                            value={this.state.location}
                            placeholder={"Guest city"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <Input
                            name={"company"}
                            label={"Company"}
                            value={this.state.company}
                            placeholder={"Company name"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <TextArea
                            name={"address"}
                            label={"Address"}
                            value={this.state.address}
                            placeholder={"Lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                            rows={5}
                        />
                        <Datepicker
                            name={"birthOfDate"}
                            label={"BoD"}
                            value={this.state.birthOfDate}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />

                        <hr className={`my-5`}/>
                        <Button
                            className={`bg-green-200 text-green-700`}
                            onClick={this.finishForm.bind(this)}
                        >
                            Submit
                        </Button>
                    </FormContainer>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    guest: state.guest,
});

const mapDispatchToProps = {
    editGuestData,
    getGuestData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(GuestEdit)
);
