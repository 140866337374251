import {guestAccessTypes} from "./type";
import {BaseAction} from "../../actions/base";

const types = guestAccessTypes;

const path = `iot/guest-card`;

const base = new BaseAction(types);

export const getGuestAccessDatas = () => (dispatch) => {
    return base.getDatas(path, dispatch);
};

export const getDeletedGuestAccessDatas = () => (dispatch) => {
    return base.getDatas(`${path}/deleted`, dispatch);
};

export const getGuestAccessData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}`, dispatch);
};

export const getDeletedGuestAccessData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}/deleted`, dispatch);
};

export const addGuestAccessData = (newData) => (dispatch) => {
    return base.addData(path, dispatch, newData);
};

export const editGuestAccessData = (id, newData) => (dispatch) => {
    return base.editData(`${path}/${id}`, dispatch, newData);
};

export const deleteGuestAccessData = (id) => (dispatch) => {
    return base.deleteData(`${path}/${id}`, dispatch, id);
};

export const restoreGuestAccessData = (id) => (dispatch) => {
    return base.restoreData(`${path}/${id}/restore`, dispatch, id);
};

export const hardDeleteGuestAccessData = (id) => (dispatch) => {
    return base.hardDeleteData(`${path}/${id}/confirmdelete`, dispatch, id);
};
