import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {deleteAccessData, getAccessDatas,} from "../../../redux/modules/Access/action";
import {ButtonIcon} from "../../../components/Button";
import {mqttClient} from "../../../config/mqtt";
import {FaSearch} from "react-icons/fa";

class Access extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };

        this.mqttClient = mqttClient;
    }

    componentDidMount() {
        this.props.getAccessDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.access.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const accessColumn = [
            {
                name: "Access",
                path: "name",
                show: true,
            },
            {
                name: "Description",
                path: "description",
                show: true,
            },
            {
                name: "Category",
                path: "category",
                show: true,
                render: (data) => data?.name,
            },
            {
                name: "Status",
                path: "status",
                show: true,
                render: (data) => (data ? "Active" : "Not Active"),
            },
            {
                name: "Locate",
                path: "UNUM",
                show: true,
                render: (data) => {
                    return (
                        <ButtonIcon
                            className={"bg-gray-500 text-white"}
                            icon={<FaSearch/>}
                            onClick={(e) => {
                                e.preventDefault();
                                this.mqttClient.publish(`sthira/rfid/${data}/test`, "1");
                            }}
                        />
                    );
                },
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        addData={true}
                        trashBin={true}
                        path={this.props.location.pathname}
                        title={"Access Data"}
                        data={this.props.access.datas}
                        columns={accessColumn}
                        action={["update", "delete"]}
                        trash={true}
                        deleteAction={(id) => this.props.deleteAccessData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    access: state.access,
});

const mapDispatchToProps = {
    getAccessDatas,
    deleteAccessData,
};

export default MainLayout(connect(mapStateToProps, mapDispatchToProps)(Access));
