import axios from "axios";

import {configData} from "./config";

const getConfig = () => {
    const config = {
        // baseURL: process.env.NODE_ENV === "production" ? 'https://api.rupira.com/' : 'https://lit-lowlands-24059.herokuapp.com/',
        baseURL: `${configData.base_url}/v1`,
        headers: {
            "Content-type": "application/json",
            app: "5f32416eb818b000338a6c90",
            Authorization: localStorage.getItem("nekot"),
            'X-Requested-With': 'XMLHttpRequest',
        },
    };
    return config;
};

const myAxios = () => {
    return axios.create(getConfig());
};

export default myAxios;
