import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {deleteIntentData, getIntentDatas,} from "../../../redux/modules/Intent/action";
import {FaEllipsisH} from "react-icons/fa";
import {ButtonIcon} from "../../../components/Button";

class Intent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getIntentDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        let datas = np?.intent?.datas
        let newDatas = []
        datas.map(ent => {
            ent.name = ent.name.replace("projects/rupira-production/agent/intents/", "");
            return newDatas.push(ent)
        })
        this.setState({
            datas: newDatas
        });
    }

    render() {
        // console.log(this.state.datas);
        const intentColumn = [
            {
                name: "Intent",
                path: "displayName",
                show: true,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        showId={false}
                        addData={true}
                        path={this.props.location.pathname}
                        title={"Intent Data"}
                        data={this.state.datas}
                        columns={intentColumn}
                        action={["update", "delete"]}
                        deleteAction={(id) => this.props.deleteIntentData(id)}
                        customDeleteId={"name"}
                        customUpdateId={"name"}
                        customUpdate={(id) => <ButtonIcon
                            className={"ml-2 bg-yellow-200 text-yellow-700"}
                            icon={<FaEllipsisH/>}
                            onClick={(e) => {
                                e.preventDefault();
                                console.log(id)
                                this.props.history.push(`intent/${id}`)
                            }}
                        />}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    intent: state.intent,
});

const mapDispatchToProps = {
    getIntentDatas,
    deleteIntentData,
};

export default MainLayout(connect(mapStateToProps, mapDispatchToProps)(Intent));
