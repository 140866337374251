import {authTypes} from "./type";

const types = authTypes;

const initialState = {
    data: {},
    isLoading: false,
    success: false,
    msg: {},
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.loading: {
            return {
                ...state,
                success: false,
                isLoading: true,
            };
        }
        case types.success: {
            return {
                ...state,
                isLoading: false,
                success: true,
                data: action.payload,
                msg: action.message,
            };
        }
        case types.successLogout: {
            return {
                ...state,
                isLoading: false,
                success: true,
                data: {},
                msg: action.message,
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default authReducer;
