import {acFreonTypeTypes} from "./type";
import {BaseAction} from "../../actions/base";

const types = acFreonTypeTypes;

const path = `iot/ac-freon-type`;

const base = new BaseAction(types);

export const getAcFreonTypeDatas = () => (dispatch) => {
    return base.getDatas(path, dispatch);
};

export const getDeletedAcFreonTypeDatas = () => (dispatch) => {
    return base.getDatas(`${path}/deleted`, dispatch);
};

export const getAcFreonTypeData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}`, dispatch);
};

export const getDeletedAcFreonTypeData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}/deleted`, dispatch);
};

export const addAcFreonTypeData = (newData) => (dispatch) => {
    return base.addData(path, dispatch, newData);
};

export const editAcFreonTypeData = (id, newData) => (dispatch) => {
    return base.editData(`${path}/${id}`, dispatch, newData);
};

export const deleteAcFreonTypeData = (id) => (dispatch) => {
    return base.deleteData(`${path}/${id}`, dispatch, id);
};

export const restoreAcFreonTypeData = (id) => (dispatch) => {
    return base.restoreData(`${path}/${id}/restore`, dispatch, id);
};

export const hardDeleteAcFreonTypeData = (id) => (dispatch) => {
    return base.hardDeleteData(`${path}/${id}/confirmdelete`, dispatch, id);
};
