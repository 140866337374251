import {timerTypes} from "./type";
import {BaseAction} from "../../actions/base";

const types = timerTypes;

const path = `iot/timer`;

const base = new BaseAction(types);

export const getTimerDatas = () => (dispatch) => {
    return base.getDatas(path, dispatch);
};

export const getDeletedTimerDatas = () => (dispatch) => {
    return base.getDatas(`${path}/deleted`, dispatch);
};

export const getTimerData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}`, dispatch);
};

export const getDeletedTimerData = (id) => (dispatch) => {
    return base.getData(`${path}/${id}/deleted`, dispatch);
};

export const addTimerData = (newData) => (dispatch) => {
    return base.addData(path, dispatch, newData);
};

export const editTimerData = (id, newData) => (dispatch) => {
    return base.editData(`${path}/${id}`, dispatch, newData);
};

export const deleteTimerData = (id) => (dispatch) => {
    return base.deleteData(`${path}/${id}`, dispatch, id);
};

export const restoreTimerData = (id) => (dispatch) => {
    return base.restoreData(`${path}/${id}/restore`, dispatch, id);
};

export const hardDeleteTimerData = (id) => (dispatch) => {
    return base.hardDeleteData(`${path}/${id}/confirmdelete`, dispatch, id);
};
