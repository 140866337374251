import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {deleteLearnData, getLearnDatas,} from "../../../redux/modules/Learn/action";

class Learn extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getLearnDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.learn.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const learnColumn = [
            {
                name: "Learn",
                path: "question",
                show: true,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        addData={true}
                        trashBin={true}
                        path={this.props.location.pathname}
                        title={"Learn Data"}
                        data={this.props.learn.datas}
                        columns={learnColumn}
                        action={["delete"]}
                        trash={true}
                        deleteAction={(id) => this.props.deleteLearnData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    learn: state.learn,
});

const mapDispatchToProps = {
    getLearnDatas,
    deleteLearnData,
};

export default MainLayout(connect(mapStateToProps, mapDispatchToProps)(Learn));
