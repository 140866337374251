export class BaseReducer {
    constructor(types, initialState, action) {
        this.types = types;
        this.initialState = initialState;
        this.action = action;
    }

    baseReducer = (state = this.initialState, action = this.action) => {
        // console.log(action);
        switch (action.type) {
            case this.types.loading: {
                return {
                    ...state,
                    success: false,
                    isLoading: true,
                };
            }
            case this.types.loadedOne: {
                return {
                    ...state,
                    isLoading: false,
                    data: action.payload,
                };
            }

            case this.types.loadedMultiple: {
                return {
                    ...state,
                    isLoading: false,
                    datas: action.payload,
                };
            }

            case this.types.success: {
                return {
                    ...state,
                    isLoading: false,
                    success: true,
                    msg: action.message,
                };
            }

            case this.types.delete: {
                let newDatas = state.datas.filter((data) => data.id !== action.payload);
                return {
                    ...state,
                    isLoading: false,
                    success: true,
                    datas: newDatas,
                    msg: action.message,
                };
            }

            case this.types.restore: {
                let newDatas = state.datas.filter((data) => data.id !== action.payload);
                return {
                    ...state,
                    isLoading: false,
                    success: true,
                    datas: newDatas,
                    msg: action.message,
                };
            }

            case this.types.hardDelete: {
                let newDatas = state.datas.filter((data) => data.id !== action.payload);
                return {
                    ...state,
                    isLoading: false,
                    success: true,
                    datas: newDatas,
                    msg: action.message,
                };
            }

            default: {
                return {
                    ...state,
                };
            }
        }
    };
}
