import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {deleteRequestData, getRequestDatas,} from "../../../redux/modules/Request/action";
import {Button, ButtonIcon} from "../../../components/Button";
import {FaSearch} from "react-icons/fa";
import {mqttClient} from "../../../config/mqtt";
import {Link} from "react-router-dom";

class AccessRequest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };

        this.mqttClient = mqttClient;
    }

    componentDidMount() {
        this.props.getRequestDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.request.datas,
        });
    }

    render() {
        const requestColumn = [
            {
                name: "Number",
                path: "UNUM",
                show: true,
            },
            {
                name: "Locate",
                path: "UNUM",
                show: true,
                render: (data) => {
                    return (
                        <ButtonIcon
                            className={"bg-gray-500 text-white"}
                            icon={<FaSearch/>}
                            onClick={(e) => {
                                e.preventDefault();
                                this.mqttClient.publish(`sthira/rfid/${data}/test`, "1");
                            }}
                        />
                    );
                },
            },
            {
                name: "Rejected",
                path: "rejected",
                show: true,
                render: (data) => String(data),
            },
            {
                name: "Action",
                path: "id",
                show: true,
                render: (data) => {
                    return (
                        <div className={"flex flex-row items-center justify-center"}>
                            <Link
                                to={`/access/add?reqid=${data}`}
                                className={"bg-green-500 text-white py-2 px-3 rounded"}
                            >
                                Accept
                            </Link>
                            <Button
                                className={"bg-red-500 text-white ml-3 py-2 px-3 rounded"}
                            >
                                Reject
                            </Button>
                        </div>
                    );
                },
            },
        ];

        const filteredAccessOnly = this.props.request?.datas.filter(
            (d) => d.requestCategory === "Access"
        );

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        trashBin={true}
                        path={this.props.location.pathname}
                        title={"Request Category Data"}
                        data={filteredAccessOnly}
                        columns={requestColumn}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    request: state.request,
});

const mapDispatchToProps = {
    getRequestDatas,
    deleteRequestData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(AccessRequest)
);
