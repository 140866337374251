import {logAccessTypes} from "./type";
import {BaseReducer} from "../../reducers/base";

const types = logAccessTypes;

const initialState = {
    datas: [],
    data: {},
    isLoading: false,
    success: false,
    msg: {},
};

const logAccessReducer = (state = initialState, action) => {
    const baseClass = new BaseReducer(types, state, action);

    return baseClass.baseReducer();
};

export default logAccessReducer;
