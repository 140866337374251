import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {Layout} from "../../components";
import AccessCategory from "./AccessCategory";
import AccessCategoryEdit from "./AccessCategory/edit";
import AccessCategoryAdd from "./AccessCategory/add";
import AccessCategoryTrash from "./AccessCategory/trash";
import Access from "./Access";
import AccessEdit from "./Access/edit";
import AccessAdd from "./Access/add";
import AccessTrash from "./Access/trash";
import AccessRequest from "./AccessRequest";
import AccessRequestTrash from "./AccessRequest/trash";
import AccessRequestEdit from "./AccessRequest/edit";
import Dashboard from "./Dashboard";
import Employee from "./Employee";
import EmployeeEdit from "./Employee/edit";
import Type from "./Type";
import TypeEdit from "./Type/edit";
import TypeAdd from "./Type/add";
import TypeTrash from "./Type/trash";
import Device from "./Device";
import DeviceEdit from "./Device/edit";
import DeviceAdd from "./Device/add";
import DeviceTrash from "./Device/trash";
import Action from "./Action";
import ActionEdit from "./Action/edit";
import ActionAdd from "./Action/add";
import ActionTrash from "./Action/trash";
import AcCategory from "./AcCategory";
import AcCategoryEdit from "./AcCategory/edit";
import AcCategoryAdd from "./AcCategory/add";
import AcCategoryTrash from "./AcCategory/trash";
import AcBrand from "./AcBrand";
import AcBrandEdit from "./AcBrand/edit";
import AcBrandAdd from "./AcBrand/add";
import AcBrandTrash from "./AcBrand/trash";
import AcFreonType from "./AcFreonType";
import AcFreonTypeEdit from "./AcFreonType/edit";
import AcFreonTypeAdd from "./AcFreonType/add";
import AcFreonTypeTrash from "./AcFreonType/trash";
import Ac from "./Ac";
import AcEdit from "./Ac/edit";
import AcAdd from "./Ac/add";
import AcTrash from "./Ac/trash";
import Guest from "./Guest";
import GuestEdit from "./Guest/edit";
import GuestAdd from "./Guest/add";
import GuestTrash from "./Guest/trash";
import GuestCard from "./GuestCard";
import GuestCardEdit from "./GuestCard/edit";
import GuestCardAdd from "./GuestCard/add";
import GuestCardTrash from "./GuestCard/trash";
import GuestAccess from "./GuestAccess";
import GuestAccessEdit from "./GuestAccess/edit";
import GuestAccessAdd from "./GuestAccess/add";
import GuestAccessTrash from "./GuestAccess/trash";
import Video from "./Video";
import VideoEdit from "./Video/edit";
import VideoAdd from "./Video/add";
import VideoTrash from "./Video/trash";
import Presentation from "./Presentation";
import PresentationEdit from "./Presentation/edit";
import PresentationAdd from "./Presentation/add";
import Controller from "./Controller";
import Connector from "./Connector";
import ConnectorAdd from "./Connector/add";
import ConnectorEdit from "./Connector/edit";
import ConnectorTrash from "./Connector/trash";
import CardReader from "./CardReader";
import CardReaderTrash from "./CardReader/trash";
import CardReaderAdd from "./CardReader/add";
import CardReaderEdit from "./CardReader/edit";
import ActionGroup from "./ActionGroup";
import ActionGroupAdd from './ActionGroup/add';
import ActionGroupTrash from './ActionGroup/trash';
import ActionGroupEdit from './ActionGroup/edit';
import GroupController from "./GroupController";
import Entity from "./Entity";
import EntityAdd from "./Entity/add";
import EntityEdit from "./Entity/edit";
import LogAccess from "./LogAccess";
import Intent from "./Intent";
import IntentAdd from "./Intent/add";
import IntentEdit from "./Intent/edit";
import Timer from "./Timer";
import TimerAdd from "./Timer/add";
import TimerEdit from "./Timer/edit";
import TimerTrash from "./Timer/trash";
import Bardi from "./Bardi";
import {connect} from "react-redux";
import {checkUser, logout, refreshToken} from "../../redux/modules/Auth/action";
import {configData} from "../../config/config";
import ReceptionistDeviceTrash from "./ReceptionistDevice/trash";
import ReceptionistDeviceAdd from "./ReceptionistDevice/add";
import ReceptionistDeviceEdit from "./ReceptionistDevice/edit";
import ReceptionistDevice from "./ReceptionistDevice";
import ShowroomDeviceTrash from "./ShowroomDevice/trash";
import ShowroomDeviceAdd from "./ShowroomDevice/add";
import ShowroomDeviceEdit from "./ShowroomDevice/edit";
import ShowroomDevice from "./ShowroomDevice";
import Learn from "./Learn";
import LearnTrash from "./Learn/trash";

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menuOpened: false,
        };
    }

    openMenu = () => {
        this.setState({
            menuOpened: true,
        });
    };

    componentDidMount() {
        this.props.refreshToken();
        this.props.checkUser();
    }

    onLogout(e) {
        e.preventDefault();
        this.props.logout(() => this.props.history.push('/login'));
    }

    render() {
        if (!localStorage.getItem("nekot")) return <Redirect to="/login"/>;
        return (
            <Layout
                pathName={this.props.history.location.pathname}
                menuOpened={this.state.menuOpened}
                profileName={this.props.auth?.data?.name ?? 'unknown'}
                profilePicture={this.props.auth?.data?.avatar ? `${
                    configData.base_url
                }/v1/storage/employee/photos/${this.props.auth?.data?.avatar}` : null}
                closeMenu={() => {
                    this.setState({
                        menuOpened: false,
                    });
                }}
                logoutAction={this.onLogout.bind(this)}
            >
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={() => (
                            <Dashboard title="Dashboard" openMenu={this.openMenu}/>
                        )}
                    />
                    <Route
                        exact
                        path="/employee"
                        render={(props) => (
                            <Employee {...props} title="Employee" openMenu={this.openMenu}/>
                        )}
                    />
                    <Route
                        path="/employee/:id"
                        render={(props) => (
                            <EmployeeEdit
                                {...props}
                                title="Employee Edit"
                                openMenu={this.openMenu}
                                pathName={"employee"}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/category"
                        render={(props) => (
                            <AccessCategory
                                {...props}
                                title="Access Category"
                                openMenu={this.openMenu}
                                pathName={"category"}
                            />
                        )}
                    />
                    <Route
                        path="/category/trash"
                        render={(props) => (
                            <AccessCategoryTrash
                                {...props}
                                title="Access Category Trash Bin"
                                openMenu={this.openMenu}
                                pathName={"category"}
                            />
                        )}
                    />
                    <Route
                        path="/category/add"
                        render={(props) => (
                            <AccessCategoryAdd
                                {...props}
                                title="Access Category Add"
                                openMenu={this.openMenu}
                                pathName={"category"}
                            />
                        )}
                    />
                    <Route
                        path="/category/:id"
                        render={(props) => (
                            <AccessCategoryEdit
                                {...props}
                                title="Access Category Edit"
                                openMenu={this.openMenu}
                                pathName={"category"}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/access"
                        render={(props) => (
                            <Access
                                {...props}
                                title="Access"
                                openMenu={this.openMenu}
                                pathName={"access"}
                            />
                        )}
                    />
                    <Route
                        path="/access/trash"
                        render={(props) => (
                            <AccessTrash
                                {...props}
                                title="Access Trash Bin"
                                openMenu={this.openMenu}
                                pathName={"access"}
                            />
                        )}
                    />
                    <Route
                        path="/access/add"
                        render={(props) => (
                            <AccessAdd
                                {...props}
                                title="Access Add"
                                openMenu={this.openMenu}
                                pathName={"access"}
                            />
                        )}
                    />
                    <Route
                        path="/access/:id"
                        render={(props) => (
                            <AccessEdit
                                {...props}
                                title="Access Edit"
                                openMenu={this.openMenu}
                                pathName={"access"}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/access-request"
                        render={(props) => (
                            <AccessRequest
                                {...props}
                                title="Access Request"
                                openMenu={this.openMenu}
                                pathName={"request"}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/access-request/trash"
                        render={(props) => (
                            <AccessRequestTrash
                                {...props}
                                title="Access Request Trash Bin"
                                openMenu={this.openMenu}
                                pathName={"request"}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/access-request/:id"
                        render={(props) => (
                            <AccessRequestEdit
                                {...props}
                                title="Access Request Accept"
                                openMenu={this.openMenu}
                                pathName={"request"}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/type"
                        render={(props) => (
                            <Type
                                {...props}
                                title="Type"
                                openMenu={this.openMenu}
                                pathName={"type"}
                            />
                        )}
                    />
                    <Route
                        path="/type/trash"
                        render={(props) => (
                            <TypeTrash
                                {...props}
                                title="Type Trash Bin"
                                openMenu={this.openMenu}
                                pathName={"type"}
                            />
                        )}
                    />
                    <Route
                        path="/type/add"
                        render={(props) => (
                            <TypeAdd
                                {...props}
                                title="Type Add"
                                openMenu={this.openMenu}
                                pathName={"type"}
                            />
                        )}
                    />
                    <Route
                        path="/type/:id"
                        render={(props) => (
                            <TypeEdit
                                {...props}
                                title="Type Edit"
                                openMenu={this.openMenu}
                                pathName={"type"}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/device"
                        render={(props) => (
                            <Device
                                {...props}
                                title="Device"
                                openMenu={this.openMenu}
                                pathName={"device"}
                            />
                        )}
                    />
                    <Route
                        path="/device/trash"
                        render={(props) => (
                            <DeviceTrash
                                {...props}
                                title="Device Trash Bin"
                                openMenu={this.openMenu}
                                pathName={"device"}
                            />
                        )}
                    />
                    <Route
                        path="/device/add"
                        render={(props) => (
                            <DeviceAdd
                                {...props}
                                title="Device Add"
                                openMenu={this.openMenu}
                                pathName={"device"}
                            />
                        )}
                    />
                    <Route
                        path="/device/:id"
                        render={(props) => (
                            <DeviceEdit
                                {...props}
                                title="Device Edit"
                                openMenu={this.openMenu}
                                pathName={"device"}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/action"
                        render={(props) => (
                            <Action
                                {...props}
                                title="Action"
                                openMenu={this.openMenu}
                                pathName={"action"}
                            />
                        )}
                    />
                    <Route
                        path="/action/trash"
                        render={(props) => (
                            <ActionTrash
                                {...props}
                                title="Action Trash Bin"
                                openMenu={this.openMenu}
                                pathName={"action"}
                            />
                        )}
                    />
                    <Route
                        path="/action/add"
                        render={(props) => (
                            <ActionAdd
                                {...props}
                                title="Action Add"
                                openMenu={this.openMenu}
                                pathName={"action"}
                            />
                        )}
                    />
                    <Route
                        path="/action/:id"
                        render={(props) => (
                            <ActionEdit
                                {...props}
                                title="Action Edit"
                                openMenu={this.openMenu}
                                pathName={"action"}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/ac-category"
                        render={(props) => (
                            <AcCategory
                                {...props}
                                title="Ac Category"
                                openMenu={this.openMenu}
                                pathName={"ac-category"}
                            />
                        )}
                    />
                    <Route
                        path="/ac-category/trash"
                        render={(props) => (
                            <AcCategoryTrash
                                {...props}
                                title="Ac Category Trash Bin"
                                openMenu={this.openMenu}
                                pathName={"ac-category"}
                            />
                        )}
                    />
                    <Route
                        path="/ac-category/add"
                        render={(props) => (
                            <AcCategoryAdd
                                {...props}
                                title="Ac Category Add"
                                openMenu={this.openMenu}
                                pathName={"ac-category"}
                            />
                        )}
                    />
                    <Route
                        path="/ac-category/:id"
                        render={(props) => (
                            <AcCategoryEdit
                                {...props}
                                title="Ac Category Edit"
                                openMenu={this.openMenu}
                                pathName={"ac-category"}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/ac-freon-type"
                        render={(props) => (
                            <AcFreonType
                                {...props}
                                title="Ac Freon Type"
                                openMenu={this.openMenu}
                                pathName={"ac-freon-type"}
                            />
                        )}
                    />
                    <Route
                        path="/ac-freon-type/trash"
                        render={(props) => (
                            <AcFreonTypeTrash
                                {...props}
                                title="Ac Freon Type Trash Bin"
                                openMenu={this.openMenu}
                                pathName={"ac-freon-type"}
                            />
                        )}
                    />
                    <Route
                        path="/ac-freon-type/add"
                        render={(props) => (
                            <AcFreonTypeAdd
                                {...props}
                                title="Ac Freon Type Add"
                                openMenu={this.openMenu}
                                pathName={"ac-freon-type"}
                            />
                        )}
                    />
                    <Route
                        path="/ac-freon-type/:id"
                        render={(props) => (
                            <AcFreonTypeEdit
                                {...props}
                                title="Ac Freon Type Edit"
                                openMenu={this.openMenu}
                                pathName={"ac-freon-type"}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/ac-brand"
                        render={(props) => (
                            <AcBrand
                                {...props}
                                title="Ac Brand"
                                openMenu={this.openMenu}
                                pathName={"ac-brand"}
                            />
                        )}
                    />
                    <Route
                        path="/ac-brand/trash"
                        render={(props) => (
                            <AcBrandTrash
                                {...props}
                                title="Ac Brand Trash Bin"
                                openMenu={this.openMenu}
                                pathName={"ac-brand"}
                            />
                        )}
                    />
                    <Route
                        path="/ac-brand/add"
                        render={(props) => (
                            <AcBrandAdd
                                {...props}
                                title="Ac Brand Add"
                                openMenu={this.openMenu}
                                pathName={"ac-brand"}
                            />
                        )}
                    />
                    <Route
                        path="/ac-brand/:id"
                        render={(props) => (
                            <AcBrandEdit
                                {...props}
                                title="Ac Brand Edit"
                                openMenu={this.openMenu}
                                pathName={"ac-brand"}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/ac"
                        render={(props) => (
                            <Ac
                                {...props}
                                title="Ac"
                                openMenu={this.openMenu}
                                pathName={"ac"}
                            />
                        )}
                    />
                    <Route
                        path="/ac/trash"
                        render={(props) => (
                            <AcTrash
                                {...props}
                                title="Ac Trash Bin"
                                openMenu={this.openMenu}
                                pathName={"ac"}
                            />
                        )}
                    />
                    <Route
                        path="/ac/add"
                        render={(props) => (
                            <AcAdd
                                {...props}
                                title="Ac Add"
                                openMenu={this.openMenu}
                                pathName={"ac"}
                            />
                        )}
                    />
                    <Route
                        path="/ac/:id"
                        render={(props) => (
                            <AcEdit
                                {...props}
                                title="Ac Edit"
                                openMenu={this.openMenu}
                                pathName={"ac"}
                            />
                        )}
                    />

                    <Route
                        exact
                        path="/guest"
                        render={(props) => (
                            <Guest
                                {...props}
                                title="Guest"
                                openMenu={this.openMenu}
                                pathName={"guest"}
                            />
                        )}
                    />
                    <Route
                        path="/guest/trash"
                        render={(props) => (
                            <GuestTrash
                                {...props}
                                title="Guest Trash Bin"
                                openMenu={this.openMenu}
                                pathName={"guest"}
                            />
                        )}
                    />
                    <Route
                        path="/guest/add"
                        render={(props) => (
                            <GuestAdd
                                {...props}
                                title="Guest Add"
                                openMenu={this.openMenu}
                                pathName={"guest"}
                            />
                        )}
                    />
                    <Route
                        path="/guest/:id"
                        render={(props) => (
                            <GuestEdit
                                {...props}
                                title="Guest Edit"
                                openMenu={this.openMenu}
                                pathName={"guest"}
                            />
                        )}
                    />

                    <Route
                        exact
                        path="/guest-card"
                        render={(props) => (
                            <GuestCard
                                {...props}
                                title="Guest Card"
                                openMenu={this.openMenu}
                                pathName={"guest-card"}
                            />
                        )}
                    />
                    <Route
                        path="/guest-card/trash"
                        render={(props) => (
                            <GuestCardTrash
                                {...props}
                                title="Guest Card Trash Bin"
                                openMenu={this.openMenu}
                                pathName={"guest-card"}
                            />
                        )}
                    />
                    <Route
                        path="/guest-card/add"
                        render={(props) => (
                            <GuestCardAdd
                                {...props}
                                title="Guest Card Add"
                                openMenu={this.openMenu}
                                pathName={"guest-card"}
                            />
                        )}
                    />
                    <Route
                        path="/guest-card/:id"
                        render={(props) => (
                            <GuestCardEdit
                                {...props}
                                title="Guest Card Edit"
                                openMenu={this.openMenu}
                                pathName={"guest-card"}
                            />
                        )}
                    />

                    <Route
                        exact
                        path="/guest-access"
                        render={(props) => (
                            <GuestAccess
                                {...props}
                                title="Guest Access"
                                openMenu={this.openMenu}
                                pathName={"guest-access"}
                            />
                        )}
                    />
                    <Route
                        path="/guest-access/trash"
                        render={(props) => (
                            <GuestAccessTrash
                                {...props}
                                title="Guest Access Trash Bin"
                                openMenu={this.openMenu}
                                pathName={"guest-access"}
                            />
                        )}
                    />
                    <Route
                        path="/guest-access/add"
                        render={(props) => (
                            <GuestAccessAdd
                                {...props}
                                title="Guest Access Add"
                                openMenu={this.openMenu}
                                pathName={"guest-access"}
                            />
                        )}
                    />
                    <Route
                        path="/guest-access/:id"
                        render={(props) => (
                            <GuestAccessEdit
                                {...props}
                                title="Guest Access Edit"
                                openMenu={this.openMenu}
                                pathName={"guest-access"}
                            />
                        )}
                    />

                    <Route
                        exact
                        path="/video"
                        render={(props) => (
                            <Video
                                {...props}
                                title="Video"
                                openMenu={this.openMenu}
                                pathName={"video"}
                            />
                        )}
                    />
                    <Route
                        path="/video/trash"
                        render={(props) => (
                            <VideoTrash
                                {...props}
                                title="Video Trash Bin"
                                openMenu={this.openMenu}
                                pathName={"video"}
                            />
                        )}
                    />
                    <Route
                        path="/video/add"
                        render={(props) => (
                            <VideoAdd
                                {...props}
                                title="Video Add"
                                openMenu={this.openMenu}
                                pathName={"video"}
                            />
                        )}
                    />
                    <Route
                        path="/video/:id"
                        render={(props) => (
                            <VideoEdit
                                {...props}
                                title="Video Edit"
                                openMenu={this.openMenu}
                                pathName={"video"}
                            />
                        )}
                    />

                    <Route
                        exact
                        path="/presentation"
                        render={(props) => (
                            <Presentation
                                {...props}
                                title="Presentation"
                                openMenu={this.openMenu}
                                pathName={"presentation"}
                            />
                        )}
                    />
                    <Route
                        path="/presentation/add"
                        render={(props) => (
                            <PresentationAdd
                                {...props}
                                title="Presentation Add"
                                openMenu={this.openMenu}
                                pathName={"presentation"}
                            />
                        )}
                    />
                    <Route
                        path="/presentation/:id"
                        render={(props) => (
                            <PresentationEdit
                                {...props}
                                title="Presentation Edit"
                                openMenu={this.openMenu}
                                pathName={"presentation"}
                            />
                        )}
                    />
                    <Route
                        path="/controller"
                        render={(props) => (
                            <Controller
                                {...props}
                                title="Controller"
                                openMenu={this.openMenu}
                                pathName={"controller"}
                            />
                        )}
                    />
                    <Route
                        path="/bardi"
                        render={(props) => (
                            <Bardi
                                {...props}
                                title="Bardi"
                                openMenu={this.openMenu}
                                pathName={"bardi"}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/connector"
                        render={(props) => (
                            <Connector
                                {...props}
                                title="Connector"
                                openMenu={this.openMenu}
                                pathName={"connector"}
                            />
                        )}
                    />

                    <Route
                        path="/connector/trash"
                        render={(props) => (
                            <ConnectorTrash
                                {...props}
                                title="Connector Trash Bin"
                                openMenu={this.openMenu}
                                pathName={"connector"}
                            />
                        )}
                    />
                    <Route
                        path="/connector/add"
                        render={(props) => (
                            <ConnectorAdd
                                {...props}
                                title="Connector"
                                openMenu={this.openMenu}
                                pathName={"connector"}
                            />
                        )}
                    />
                    <Route
                        path="/connector/:id"
                        render={(props) => (
                            <ConnectorEdit
                                {...props}
                                title="Connector"
                                openMenu={this.openMenu}
                                pathName={"connector"}
                            />
                        )}
                    />

                    <Route
                        exact
                        path="/card-reader"
                        render={(props) => (
                            <CardReader
                                {...props}
                                title="Card Reader"
                                openMenu={this.openMenu}
                                pathName={"card-reader"}
                            />
                        )}
                    />
                    <Route
                        path="/card-reader/trash"
                        render={(props) => (
                            <CardReaderTrash
                                {...props}
                                title="Card Reader"
                                openMenu={this.openMenu}
                                pathName={"card-reader"}
                            />
                        )}
                    />
                    <Route
                        path="/card-reader/add"
                        render={(props) => (
                            <CardReaderAdd
                                {...props}
                                title="Card Reader"
                                openMenu={this.openMenu}
                                pathName={"card-reader"}
                            />
                        )}
                    />
                    <Route
                        path="/card-reader/:id"
                        render={(props) => (
                            <CardReaderEdit
                                {...props}
                                title="Card Reader"
                                openMenu={this.openMenu}
                                pathName={"card-reader"}
                            />
                        )}
                    />

                    <Route
                        exact
                        path="/action-group"
                        render={(props) => (
                            <ActionGroup
                                {...props}
                                title="Action Group"
                                openMenu={this.openMenu}
                                pathName={"action-group"}
                            />
                        )}
                    />
                    <Route
                        path="/action-group/trash"
                        render={(props) => (
                            <ActionGroupTrash
                                {...props}
                                title="Action Group"
                                openMenu={this.openMenu}
                                pathName={"action-group"}
                            />
                        )}
                    />
                    <Route
                        path="/action-group/add"
                        render={(props) => (
                            <ActionGroupAdd
                                {...props}
                                title="Action Group"
                                openMenu={this.openMenu}
                                pathName={"action-group"}
                            />
                        )}
                    />
                    <Route
                        path="/action-group/:id"
                        render={(props) => (
                            <ActionGroupEdit
                                {...props}
                                title="Action Group"
                                openMenu={this.openMenu}
                                pathName={"action-group"}
                            />
                        )}
                    />
                    <Route
                        path="/group-controller"
                        render={(props) => (
                            <GroupController
                                {...props}
                                title="Group Controller"
                                openMenu={this.openMenu}
                                pathName={"group-controller"}
                            />
                        )}
                    />

                    <Route
                        exact
                        path="/entity"
                        render={(props) => (
                            <Entity
                                {...props}
                                title="Entity"
                                openMenu={this.openMenu}
                                pathName={"entity"}
                            />
                        )}
                    />
                    <Route
                        path="/entity/add"
                        render={(props) => (
                            <EntityAdd
                                {...props}
                                title="Entity"
                                openMenu={this.openMenu}
                                pathName={"entity"}
                            />
                        )}
                    />
                    <Route
                        path="/entity/:id"
                        render={(props) => (
                            <EntityEdit
                                {...props}
                                title="Entity"
                                openMenu={this.openMenu}
                                pathName={"entity"}
                            />
                        )}
                    />

                    <Route
                        exact
                        path="/log"
                        render={(props) => (
                            <LogAccess
                                {...props}
                                title="Log"
                                openMenu={this.openMenu}
                                pathName={"log"}
                            />
                        )}
                    />

                    <Route
                        exact
                        path="/intent"
                        render={(props) => (
                            <Intent
                                {...props}
                                title="Intent"
                                openMenu={this.openMenu}
                                pathName={"intent"}
                            />
                        )}
                    />
                    <Route
                        path="/intent/add"
                        render={(props) => (
                            <IntentAdd
                                {...props}
                                title="Intent"
                                openMenu={this.openMenu}
                                pathName={"intent"}
                            />
                        )}
                    />
                    <Route
                        path="/intent/:id"
                        render={(props) => (
                            <IntentEdit
                                {...props}
                                title="Intent"
                                openMenu={this.openMenu}
                                pathName={"intent"}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/timer"
                        render={(props) => (
                            <Timer
                                {...props}
                                title="Timer"
                                openMenu={this.openMenu}
                                pathName={"timer"}
                            />
                        )}
                    />
                    <Route
                        path="/timer/trash"
                        render={(props) => (
                            <TimerTrash
                                {...props}
                                title="Timer Trash Bin"
                                openMenu={this.openMenu}
                                pathName={"timer"}
                            />
                        )}
                    />
                    <Route
                        path="/timer/add"
                        render={(props) => (
                            <TimerAdd
                                {...props}
                                title="Timer Add"
                                openMenu={this.openMenu}
                                pathName={"timer"}
                            />
                        )}
                    />
                    <Route
                        path="/timer/:id"
                        render={(props) => (
                            <TimerEdit
                                {...props}
                                title="Timer Edit"
                                openMenu={this.openMenu}
                                pathName={"timer"}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/receptionist-device"
                        render={(props) => (
                            <ReceptionistDevice
                                {...props}
                                title="ReceptionistDevice"
                                openMenu={this.openMenu}
                                pathName={"receptionist-device"}
                            />
                        )}
                    />
                    <Route
                        path="/receptionist-device/trash"
                        render={(props) => (
                            <ReceptionistDeviceTrash
                                {...props}
                                title="Receptionist Device Trash Bin"
                                openMenu={this.openMenu}
                                pathName={"receptionist-device"}
                            />
                        )}
                    />
                    <Route
                        path="/receptionist-device/add"
                        render={(props) => (
                            <ReceptionistDeviceAdd
                                {...props}
                                title="ReceptionistDevice Add"
                                openMenu={this.openMenu}
                                pathName={"receptionist-device"}
                            />
                        )}
                    />
                    <Route
                        path="/receptionist-device/:id"
                        render={(props) => (
                            <ReceptionistDeviceEdit
                                {...props}
                                title="ReceptionistDevice Edit"
                                openMenu={this.openMenu}
                                pathName={"receptionist-device"}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/showroom-device"
                        render={(props) => (
                            <ShowroomDevice
                                {...props}
                                title="Showroom Device"
                                openMenu={this.openMenu}
                                pathName={"showroom-device"}
                            />
                        )}
                    />
                    <Route
                        path="/showroom-device/trash"
                        render={(props) => (
                            <ShowroomDeviceTrash
                                {...props}
                                title="Showroom Device Trash Bin"
                                openMenu={this.openMenu}
                                pathName={"showroom-device"}
                            />
                        )}
                    />
                    <Route
                        path="/showroom-device/add"
                        render={(props) => (
                            <ShowroomDeviceAdd
                                {...props}
                                title="Showroom Device Add"
                                openMenu={this.openMenu}
                                pathName={"showroom-device"}
                            />
                        )}
                    />
                    <Route
                        path="/showroom-device/:id"
                        render={(props) => (
                            <ShowroomDeviceEdit
                                {...props}
                                title="Showroom Device Edit"
                                openMenu={this.openMenu}
                                pathName={"showroom-device"}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/learn"
                        render={(props) => (
                            <Learn
                                {...props}
                                title="Learn"
                                openMenu={this.openMenu}
                                pathName={"learn"}
                            />
                        )}
                    />
                    <Route
                        path="/learn/trash"
                        render={(props) => (
                            <LearnTrash
                                {...props}
                                title="Learn Trash Bin"
                                openMenu={this.openMenu}
                                pathName={"learn"}
                            />
                        )}
                    />
                </Switch>
            </Layout>
        );
    }
}

export default connect(
    mapStateToProps, {checkUser, refreshToken, logout}
)(Index);
