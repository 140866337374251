import React from "react";
import MainLayout from "../../../hoc/main";
import {Checkbox, FormContainer, Grid, Input} from "../../../components";
import {connect} from "react-redux";
import {editEmployeeData, getEmployeeData,} from "../../../redux/modules/Employee/action";
import {getAccessDatas} from "../../../redux/modules/Access/action";
import {Button} from "../../../components/Button";
import {Redirect} from "react-router-dom";

class EmployeeEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            nip: "",
            searchAccess: "",
            access: [],
        };
    }

    componentDidMount() {
        this.props.getEmployeeData(this.props.match.params.id);
        this.props.getAccessDatas();
    }

    UNSAFE_componentWillReceiveProps = (np) => {
        let access = np.employee.data?.access?.map((el) => {
            return el.id;
        });
        this.setState({
            name: np.employee.data.name,
            nip: np.employee.data.nip,
            access,
        });
    };

    onCheckChange(id, e) {
        let checked = e.target.checked;
        this.setState((prevState) => {
            if (checked) {
                let access = [...prevState.access, id];

                return {access};
            } else {
                let access = prevState.access;
                const index = access.indexOf(id);

                if (index > -1) {
                    access.splice(index, 1);
                }

                return {access};
            }
        });
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });
    };

    finishForm(e) {
        e.preventDefault();
        this.props.editEmployeeData(this.props.match.params.id, this.state);
    }

    render() {
        if (this.props.employee?.success) return <Redirect to="/employee"/>;

        let filteredAccess = this.props.access.datas.filter((acc) =>
            acc.name.toLowerCase().includes(this.state.searchAccess.toLowerCase())
        );
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <FormContainer className={`pt-0`}>
                        <Input
                            name={"name"}
                            label={"Employee Name"}
                            value={this.state.name}
                            placeholder={"John Doe"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                            disabled={true}
                        />
                        <Input
                            name={"nip"}
                            label={"Number"}
                            value={this.state.nip}
                            placeholder={"156"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                            disabled={true}
                        />
                        <hr className={`my-5`}/>
                        <p className={"font-medium"}>Access</p>
                        <Input
                            name={"searchAccess"}
                            value={this.state.searchAccess}
                            placeholder={"Search Something...."}
                            className={"w-full md:w-1/2 xl:w-1/3 mt-5"}
                            onChange={this.onChangeValue}
                        />
                        <div
                            className="mt-5 w-full md:w-1/2 xl:w-1/3 pt-0 max-h-80 border rounded  overflow-y-auto border-gray-200">
                            <div className={"p-5"}>
                                {filteredAccess?.map((data, i) => (
                                    <div key={i}>
                                        <Checkbox
                                            label={data.name}
                                            key={i}
                                            checked={this.state.access?.includes(data.id)}
                                            onChange={this.onCheckChange.bind(this, data.id)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <hr className={`my-5`}/>
                        <Button
                            className={`bg-green-200 text-green-700`}
                            onClick={this.finishForm.bind(this)}
                        >
                            Submit
                        </Button>
                    </FormContainer>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    employee: state.employee,
    access: state.access,
});

const mapDispatchToProps = {
    getEmployeeData,
    getAccessDatas,
    editEmployeeData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(EmployeeEdit)
);
