import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {getDeletedTypeDatas, hardDeleteTypeData, restoreTypeData,} from "../../../redux/modules/Type/action";

class TypeTrash extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getDeletedTypeDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.type.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const typeColumn = [
            {
                name: "Type",
                path: "name",
                show: true,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        path={this.props.location.pathname}
                        title={"Type Category Data"}
                        data={this.props.type.datas}
                        columns={typeColumn}
                        trashAction={true}
                        deleteAction={(id) => this.props.hardDeleteTypeData(id)}
                        restoreAction={(id) => this.props.restoreTypeData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    type: state.type,
});

const mapDispatchToProps = {
    getDeletedTypeDatas,
    hardDeleteTypeData,
    restoreTypeData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(TypeTrash)
);
