import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {deleteEntityData, getEntityDatas,} from "../../../redux/modules/Entity/action";
import {FaEllipsisH} from "react-icons/fa";
import {ButtonIcon} from "../../../components/Button";

class Entity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getEntityDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        let datas = np?.entity?.datas
        let newDatas = []
        datas.map(ent => {
            ent.name = ent.name.replace("projects/rupira-production/agent/entityTypes/", "");
            return newDatas.push(ent)
        })
        this.setState({
            datas: newDatas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const entityColumn = [
            {
                name: "Entity",
                path: "displayName",
                show: true,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        showId={false}
                        addData={true}
                        path={this.props.location.pathname}
                        title={"Entity Data"}
                        data={this.state.datas}
                        columns={entityColumn}
                        action={["update", "delete"]}
                        deleteAction={(id) => this.props.deleteEntityData(id)}
                        customDeleteId={"name"}
                        customUpdateId={"name"}
                        customUpdate={(id) => <ButtonIcon
                            className={"ml-2 bg-yellow-200 text-yellow-700"}
                            icon={<FaEllipsisH/>}
                            onClick={(e) => {
                                e.preventDefault();
                                console.log(this.props.history.push(`entity/${id}`))
                            }}
                        />}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    entity: state.entity,
});

const mapDispatchToProps = {
    getEntityDatas,
    deleteEntityData,
};

export default MainLayout(connect(mapStateToProps, mapDispatchToProps)(Entity));
