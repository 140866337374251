import {FaChevronLeft, FaChevronRight} from "react-icons/fa";

export const Pagination = ({
                               currentPage,
                               perPage,
                               totalData,
                               onClickPage,
                           }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalData / perPage); i++) {
        pageNumbers.push(i);
    }

    const ButtonPagination = ({onClick, value, disabled}) => {
        // console.log(value);
        return (
            <button
                disabled={disabled}
                onClick={onClick}
                className={`px-3 py-2 disabled:opacity-50 appearance-none focus:outline-none ${
                    currentPage === value ? "bg-green-200" : null
                }`}
            >
                {value}
            </button>
        );
    };

    const onClickFunction = (e, value) => {
        e.preventDefault();

        onClickPage(value);
    };

    return (
        <div className={`text-sm font-light`}>
            <nav className={`flex items-center border-2 rounded-md`}>
                <ButtonPagination
                    disabled={currentPage === 1}
                    onClick={(e) => onClickFunction(e, currentPage - 1)}
                    value={<FaChevronLeft/>}
                />
                <ButtonPagination onClick={(e) => onClickFunction(e, 1)} value={1}/>
                {currentPage >= 5 ? <span className={`mx-3 my-2`}>...</span> : null}

                {currentPage < 5
                    ? pageNumbers
                        .slice(1, 5)
                        .map((num) => (
                            <ButtonPagination
                                key={num}
                                onClick={(e) => onClickFunction(e, num)}
                                value={num}
                            />
                        ))
                    : currentPage >= pageNumbers.length - 4 &&
                    currentPage <= pageNumbers.length
                        ? pageNumbers
                            .slice(pageNumbers.length - 5, pageNumbers.length - 1)
                            .map((num) => (
                                <ButtonPagination
                                    key={num}
                                    onClick={(e) => onClickFunction(e, num)}
                                    value={num}
                                />
                            ))
                        : pageNumbers
                            .slice(currentPage - 2, currentPage + 1)
                            .map((num) => (
                                <ButtonPagination
                                    key={num}
                                    onClick={(e) => onClickFunction(e, num)}
                                    value={num}
                                />
                            ))}

                {currentPage < pageNumbers.length - 4 ? (
                    <span className={`mx-3 my-2`}>...</span>
                ) : null}

                {pageNumbers.length > 5 ? (
                    <ButtonPagination
                        onClick={(e) =>
                            onClickFunction(e, pageNumbers[pageNumbers.length - 1])
                        }
                        value={pageNumbers[pageNumbers.length - 1]}
                    />
                ) : null}

                <ButtonPagination
                    disabled={currentPage === pageNumbers[pageNumbers.length - 1]}
                    onClick={(e) => onClickFunction(e, currentPage + 1)}
                    value={<FaChevronRight/>}
                />
            </nav>
        </div>
    );
};
