import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {
    getDeletedConnectorDatas,
    hardDeleteConnectorData,
    restoreConnectorData,
} from "../../../redux/modules/Connector/action";

class ConnectorTrash extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getDeletedConnectorDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.connector.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const connectorColumn = [
            {
                name: "Connector",
                path: "name",
                show: true,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        path={this.props.location.pathname}
                        title={"Connector Category Data"}
                        data={this.props.connector.datas}
                        columns={connectorColumn}
                        trashAction={true}
                        deleteAction={(id) => this.props.hardDeleteConnectorData(id)}
                        restoreAction={(id) => this.props.restoreConnectorData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    connector: state.connector,
});

const mapDispatchToProps = {
    getDeletedConnectorDatas,
    hardDeleteConnectorData,
    restoreConnectorData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(ConnectorTrash)
);
