import "./styles/main.css";
import store from "./redux/store";
import {Provider} from "react-redux";
import {Route, Switch} from "react-router-dom";
import Admin from "./pages/Admin";
import "react-toastify/dist/ReactToastify.css";
import {ToastContainer} from "react-toastify";
import Login from "./pages/Login";

function App(props) {
    return (
        <Provider store={store}>
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/" component={Admin}/>
            </Switch>
            <ToastContainer
                closeOnClick
                autoClose={2000}
                limit={3}
                newestOnTop={true}
            />
        </Provider>
    );
}

export default App;
