export const Cardlytics = ({
                               title,
                               data,
                               unit,
                               icon,
                               className,
                               color,
                               test,
                           }) => {
    const selectedColor = color;

    return (
        <div className={`p-5 bg-white rounded-lg shadow-md ${className}`}>
            <div className=" flex items-center">
                <div
                    className={`p-3 sm:p-3 text-3xl lg:text-5xl bg-${selectedColor}-500 text-${selectedColor}-200 rounded-md`}
                >
                    {icon}
                </div>
                <div className="ml-3">
                    <p className={"text-lg xl:text-2xl text-gray-500"}>{title}</p>
                    <p className={"text-md xl:text-xl font-bold text-gray-600 mt-3"}>
                        {data + " " + unit}
                    </p>
                </div>
            </div>
        </div>
    );
};
