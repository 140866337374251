import React from "react";
import MainLayout from "../../../hoc/main";
import {FormContainer, Grid, Input} from "../../../components";
import {connect} from "react-redux";
import {editIntentData, getIntentData,} from "../../../redux/modules/Intent/action";
import {getEntityDatas,} from "../../../redux/modules/Entity/action";
import {Button, ButtonIcon} from "../../../components/Button";
import {Redirect} from "react-router-dom";
import {FaArrowRight} from "react-icons/fa";

class IntentEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayName: "",
            trainingPhrases: [],
            parameters: [],
            messages: [],
            webhookState: "WEBHOOK_STATE_ENABLED",
            newTrainingPhrases: "",
            newMessage: ""
        };

        this.addNewTrainingPhrases = this.addNewTrainingPhrases.bind(this)
    }

    componentDidMount() {
        this.props.getIntentData(this.props.match.params.id);
        this.props.getEntityDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            displayName: np.intent.data.displayName,
            trainingPhrases: np.intent.data.trainingPhrases,
            messages: np.intent.data.messages,
            parameters: np.intent.data.parameters
        });
    }

    onChangeValue = (e) => {
        const {value, name} = e.target;

        this.setState({
            [name]: value,
        });
    };

    finishForm(e) {
        e.preventDefault();
        this.props.editIntentData(this.props.match.params.id, this.state);
    }

    addNewTrainingPhrases(e) {
        e.preventDefault();
        let dataInput = this?.state?.newTrainingPhrases.toLowerCase();
        if (dataInput === "") return;
        let tempDataInput = this?.state?.newTrainingPhrases.toLowerCase();
        let entityDatas = this.props?.entity?.datas;
        let indexData = [];

        entityDatas.map((ed, i) => {
            let sortedSynonyms = ed?.entities[0].synonyms.sort((a, b) => b.length - a.length);

            // eslint-disable-next-line array-callback-return
            return sortedSynonyms.map((entitySynonym, iSynonym) => {
                if (dataInput.indexOf(entitySynonym) !== -1) {
                    indexData.push({
                        text: tempDataInput.substr(tempDataInput.indexOf(entitySynonym), entitySynonym.length),
                        startIndex: tempDataInput.indexOf(entitySynonym),
                        lastIndex: tempDataInput.indexOf(entitySynonym) + tempDataInput.substr(tempDataInput.indexOf(entitySynonym), entitySynonym.length).length,
                        entityType: `@${entityDatas[i].displayName}`,
                        alias: `${entityDatas[i].displayName + iSynonym}`
                    });

                    dataInput = dataInput.replace(dataInput.substr(dataInput.indexOf(entitySynonym), entitySynonym.length), "");
                }
            })
        })
        if (indexData.length > 0) {
            indexData.sort((a, b) => a.startIndex - b.startIndex);
            // hai split wall dirumah istri saya yang ada wife nya
            let tempIndexData = [...indexData];
            indexData.map((data, i) => {
                if (i === 0) {
                    if (data.startIndex > 0) {
                        return tempIndexData.push({
                            startIndex: 0,
                            lastIndex: data.startIndex
                        });
                    }
                }
                return tempIndexData.push({
                    startIndex: indexData[i - 1].lastIndex,
                    lastIndex: data.startIndex
                })
            })

            if (indexData[indexData.length - 1].lastIndex < tempDataInput.length - 1) {
                tempIndexData.push({
                    startIndex: indexData[indexData.length - 1].lastIndex,
                    lastIndex: tempDataInput.length
                });
            }

            tempIndexData.sort((a, b) => a.startIndex - b.startIndex);
            let partData = tempIndexData.map((data) => {
                return {
                    text: tempDataInput.slice(data.startIndex, data.lastIndex),
                    entityType: data.entityType ?? '',
                    alias: data.alias ?? ''
                }
            });
            this.setState(prevState => {
                let trainingPhrases = [...prevState.trainingPhrases];

                trainingPhrases.push({parts: partData})
                return {trainingPhrases, newTrainingPhrases: ""}
            })
        } else {
            this.setState(prevState => {
                let trainingPhrases = [...prevState.trainingPhrases];

                trainingPhrases.push({
                    parts: [{
                        text: dataInput,
                        entityType: '',
                        alias: ''
                    }]
                })
                return {trainingPhrases, newTrainingPhrases: ""}
            })
        }

        e.target.value = "";
    }

    addMessage(e) {
        e.preventDefault()

        this.setState(prevState => {
            console.log(this.state.newMessage);
            let messages = [...prevState.messages];

            messages[0]?.text?.text?.push(
                this.state.newMessage
            )

            return {messages, newMessage: ""}
        })

        e.target.value = "";
    }

    render() {
        if (this.props.intent?.success) return <Redirect to="/intent"/>;
        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <FormContainer className={`pt-0`}>
                        <Input
                            name={"displayName"}
                            label={"Intent name"}
                            value={this.state.displayName}
                            placeholder={"Lorem"}
                            className={"w-full md:w-1/2 xl:w-1/3"}
                            onChange={this.onChangeValue}
                        />
                        <h2 className={"mt-5 font-medium"}>Training Phrases</h2>
                        <div className="mt-5 border-2 border-gray-100 p-5 rounded-md">
                            {this.state?.trainingPhrases?.map((tp, itp) =>
                                <div className="my-3 w-full md:w-1/2 flex flex-row ">
                                    <div key={itp}
                                         className="p-3 bg-gray-100 rounded-md w-full focus:outline-none cursor-default">
                                        {tp.parts.map(e => <span
                                            title={e.entityType + " | " + e.alias}
                                            className={e.entityType !== "" && 'bg-yellow-300'}>
                                              {e.text}
                                            </span>)}
                                    </div>
                                    <button className={"px-3 bg-yellow-300 text-yellow-500 rounded ml-5 my-2"}
                                            onClick={() => {
                                                this.setState(prevState => {
                                                    let trainingPhrases = [...prevState.trainingPhrases];

                                                    trainingPhrases.splice(itp, 1)

                                                    return {trainingPhrases}
                                                })
                                            }}>X
                                    </button>
                                </div>
                            )}
                            <div className="my-3 w-full md:w-1/2 flex flex-row ">
                                <input className={"p-3 border-2 border-gray-100 rounded-md w-full focus:outline-none"}
                                       type="text" name={"newTrainingPhrases"} value={this.state.newTrainingPhrases}
                                       onChange={this.onChangeValue} placeholder={"New training phrases here...."}/>
                                <ButtonIcon
                                    className={"my-2 ml-2 bg-green-200 text-green-700"}
                                    icon={<FaArrowRight/>}
                                    onClick={this.addNewTrainingPhrases.bind(this)}
                                />
                            </div>
                        </div>

                        <h2 className={"mt-5 font-medium"}>Messages</h2>
                        <div className="mt-5 border-2 border-gray-100 p-5 rounded-md">
                            {this.state?.messages?.map((message, index) =>
                                message?.text?.text?.map((t, i) => <div className="my-3 w-full md:w-1/2 flex flex-row ">
                                    <div key={i}
                                         className="p-3 bg-gray-100 rounded-md w-full focus:outline-none cursor-default">
                                        {t}
                                    </div>
                                    <button className={"px-3 bg-yellow-300 text-yellow-500 rounded ml-5 my-2"}
                                            onClick={() => {
                                                this.setState(prevState => {
                                                    let messages = [...prevState.messages];

                                                    messages[0]?.text?.text?.splice(i, 1)

                                                    return {messages}
                                                })
                                            }}>X
                                    </button>
                                </div>)
                            )}
                            <div className="my-3 w-full md:w-1/2 flex flex-row ">
                                <input className={"p-3 border-2 border-gray-100 rounded-md w-full focus:outline-none"}
                                       type="text" name={"newMessage"} value={this.state.newMessage}
                                       onChange={this.onChangeValue} placeholder={"New message here...."}/>
                                <ButtonIcon
                                    className={"my-2 ml-2 bg-green-200 text-green-700"}
                                    icon={<FaArrowRight/>}
                                    onClick={this.addMessage.bind(this)}
                                />
                            </div>
                        </div>
                        <hr className={`my-5`}/>
                        <Button
                            className={`bg-green-200 text-green-700`}
                            onClick={this.finishForm.bind(this)}
                        >
                            Submit
                        </Button>
                    </FormContainer>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    intent: state.intent,
    entity: state.entity,
});

const mapDispatchToProps = {
    editIntentData,
    getIntentData,
    getEntityDatas
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(IntentEdit)
);
