import {entityTypes} from "./type";
import {BaseReducer} from "../../reducers/base";

const types = entityTypes;

const initialState = {
    datas: [],
    data: {},
    isLoading: false,
    success: false,
    msg: {},
};

const entityReducer = (state = initialState, action) => {
    const baseClass = new BaseReducer(types, state, action);

    return baseClass.baseReducer();
};

export default entityReducer;
