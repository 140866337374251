import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {deleteAcFreonTypeData, getAcFreonTypeDatas,} from "../../../redux/modules/AcFreonType/action";

class AcFreonType extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getAcFreonTypeDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.acFreonType.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const acFreonTypeColumn = [
            {
                name: "Access FreonType",
                path: "name",
                show: true,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        addData={true}
                        trashBin={true}
                        path={this.props.location.pathname}
                        title={"Access FreonType Data"}
                        data={this.props.acFreonType.datas}
                        columns={acFreonTypeColumn}
                        action={["update", "delete"]}
                        trash={true}
                        deleteAction={(id) => this.props.deleteAcFreonTypeData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    acFreonType: state.acFreonType,
});

const mapDispatchToProps = {
    getAcFreonTypeDatas,
    deleteAcFreonTypeData,
};

export default MainLayout(
    connect(mapStateToProps, mapDispatchToProps)(AcFreonType)
);
