import React from "react";
import MainLayout from "../../../hoc/main";
import {Grid, TableData} from "../../../components";
import {connect} from "react-redux";
import {deleteShowroomDeviceData, getShowroomDeviceDatas,} from "../../../redux/modules/ShowroomDevice/action";

class ShowroomDevice extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
        };
    }

    componentDidMount() {
        this.props.getShowroomDeviceDatas();
    }

    UNSAFE_componentWillReceiveProps(np) {
        this.setState({
            datas: np.showroomDevice.datas,
        });
    }

    render() {
        // console.log(this.state.datas);
        const showroomDeviceColumn = [
            {
                name: "Name",
                path: "name",
                show: true,
            },
            {
                name: "UNUM",
                path: "UNUM",
                show: true,
            },
            {
                name: "Charger Name",
                path: "charger",
                show: true,
                render: (data) => data.result.name,
            },
            {
                name: "Receptionist Name",
                path: "receptionist",
                show: true,
                render: (data) => data.name,
            },
            {
                name: "Used By",
                path: "usedBy",
                show: true,
                render: (data) => data?.guest?.name,
            },
        ];

        return (
            <div className="p-6 mt-20 xl:mt-0 ">
                <Grid>
                    <TableData
                        addData={true}
                        trashBin={true}
                        path={this.props.location.pathname}
                        title={"Showroom Device Data"}
                        data={this.props.showroomDevice.datas}
                        columns={showroomDeviceColumn}
                        action={["update", "delete"]}
                        trash={true}
                        deleteAction={(id) => this.props.deleteShowroomDeviceData(id)}
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    showroomDevice: state.showroomDevice,
});

const mapDispatchToProps = {
    getShowroomDeviceDatas,
    deleteShowroomDeviceData,
};

export default MainLayout(connect(mapStateToProps, mapDispatchToProps)(ShowroomDevice));
